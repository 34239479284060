

import React, { useState } from "react";

export default function HeaderVisor({ investigadores, filtrar }) {
   var datos = investigadores
     .map((obs) =>
       obs.user.name
         ? { id: obs.user.id, name: obs.user.name }
         : { id: obs.user.id, name: obs.user.login }
     )
     .sort((a, b) => {
       if (a.name.toLowerCase() > b.name.toLowerCase()) {
         return 1;
       }
       if (a.name.toLowerCase() < b.name.toLowerCase()) {
         return -1;
       }
       return 0;
     });
   var hash = {};
   datos = datos.filter(function (current) {
     var exists = !hash[current.id];
     hash[current.id] = true;
     return exists;
   });
 
   // console.log(datos);
   const [hide, setHide] = useState(true);
   const [inv, setInv] = useState("");
   const [Fini, setFini] = useState("");
   const [Ffin, setFfin] = useState("");
   const [Conse, setConse] = useState("");
   const [Fini1, setFini1] = useState("");
   const [Ffin1, setFfin1] = useState("");
   const [LuPla, setLuPla] = useState("");
   const [VerN, setVerN] = useState(false);
   const consultar = () => {
     var avistamientos = {};
     var monitoreo = {};
     if (inv != "") {
       avistamientos["inv"] = inv;
     }
     if (Fini != "") {
       avistamientos["Fini"] = Fini;
     }
     if (Ffin != "") {
       avistamientos["Ffin"] = Ffin;
     }
     if (Conse != "") {
       monitoreo["Conse"] = Conse;
     }
     if (Fini1 != "") {
       monitoreo["Fini1"] = Fini1;
     }
     if (Ffin1 != "") {
       monitoreo["Ffin1"] = Ffin1;
     }
     if (LuPla != "") {
       monitoreo["LuPla"] = LuPla;
     }
     if (VerN != false) {
       monitoreo["VerN"] = VerN;
     }
 
     filtrar({
       avistamientos: Object.keys(avistamientos).length > 0 ? avistamientos : "",
       monitoreo: Object.keys(monitoreo).length > 0 ? monitoreo : "",
     });
   };
   return (
     <div>
       <section className="titseccion bg-titleseccion">
         <div
           className="container-xl h-100 d-flex align-items-center"
           style={{ flexWrap: "wrap" }}
         >
           <div className="col-sm-12 col-md-6">
             <h2 className="title-modulo">Visor Avistamientos de Tiburones, Rayas y Quimeras de Colombia</h2>
           </div>
           <div className="col-9 col-sm-6 col-md-3 pt-3"></div>
           <div className="col-3 col-sm-2 col-md-1 pl-0">
             <div className="dropdown">
               <button
                 className="btn btn-gray dropdown-toggle"
                 onClick={() => setHide(!hide)}
               >
                 <i className="fa fa-sliders" aria-hidden="true"></i> Filtros
               </button>
 
               {!hide && (
                 <div
                   className="dropdown-menu dropdown-menu-right p-0 show"
                   //   onBlur={() => setHide(true)}
                 >
                   <div className="d-flex a-filtros">
                     <div className="col-md p-3 br-1">
                       <h6>Avistamientos</h6>
                       <div className="form-row">
                         <div className="col-sm-6 col-md-6">
                           <label className="f-12">Fecha inicial</label>
                           <input
                             value={Fini}
                             onChange={(e) => setFini(e.target.value)}
                             type="date"
                             className="form-control f-12"
                             id="v03"
                             placeholder="dd/mm/aa"
                             required
                           />
                         </div>
                         <div className="col-sm-6 col-md-6">
                           <label className="f-12">Fecha final</label>
                           <input
                             type="date"
                             value={Ffin}
                             onChange={(e) => setFfin(e.target.value)}
                             className="form-control f-12"
                             id="v04"
                             placeholder="dd/mm/aa"
                             required
                           />
                         </div>
                         <div className="col-md-12">
                           <label className="f-12">Investigador</label>
                           <select
                             className="custom-select f-12"
                             id="v05"
                             onChange={(e) => setInv(e.target.value)}
                             value={inv}
                             required
                           >
                             <option value="">Seleccione...</option>
                             {datos &&
                               datos.map((v) => (
                                 <option value={v.id}>
                                   {v.name.toUpperCase()}
                                 </option>
                               ))}
                           </select>
                         </div>
                         <hr />
                         <button
                           className="btn btn-gray"
                           onClick={() => {
                             setInv("");
                             setFini("");
                             setFfin("");
                             consultar();
                           }}
                         >
                           Limpiar Filtros
                         </button>
                         <button
                           className="btn btn-primary"
                           type="button"
                           id="button-addon2"
                           onClick={() => {
                             setHide(true);
                             consultar();
                           }}
                         >
                           Buscar
                         </button>
                       </div>
                     </div>
                   </div>
                 </div>
               )}
             </div>
           </div>
           <div className="col-sm-3 col-md-2">
             <a href="https://minambiente.gov.co">
               <img
                 src=".http://www.invemar.org.co/Invemar3.0-theme/images/img/logos/min-ambiente-gov.jpg"
                 width="200"
                 alt=""
               />
             </a>
           </div>
         </div>
       </section>
     </div>
   );
 }
 