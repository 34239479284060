import { Link, useHistory } from "react-router-dom";
import { URL_APP } from "../../Routes";
import { TXT_APP } from "../../Routes";
import { connect } from "react-redux";
import React, { useState, useEffect } from "react";

function NavSub({ error, fetching, doLoginAction ,active}) {
   let url = URL_APP();

   const history = useHistory();

   const [show, setShow] = useState(false);
   let mostrar = () => {
      setShow(true);
   };
   let ocultar = () => {
      setShow(false);
   };
   //cerrar sesion
   const logOut = (e) => {
      e.preventDefault();
      history.push("/avistamiento");
   };

   return (
      <section className='menubar'>
         <nav className='navbar navbar-expand-lg navbar-light bg-light'>
            <Link className='navbar-brand' to={url.home}>
               MENÚ
            </Link>
            <button
               className='navbar-toggler'
               type='button'
               data-toggle='collapse'
               data-target='#navbarSupportedContent'
               aria-controls='navbarSupportedContent'
               aria-expanded='false'
               aria-label='Toggle navigation'
            >
               <span className='navbar-toggler-icon'></span>
            </button>

            <div
               className='collapse navbar-collapse'
               id='navbarSupportedContent'
            >
               <ul className='navbar-nav invemar mr-auto'>
             <li className='nav-item'>
                     <Link className='nav-link' exact to={url.avistamiento}>
                        Registrar Avistamientos
                     </Link>
                 
                  </li>

                  <li className='nav-item'>
                     <Link className='nav-link' exact to={url.visor}>
                     Registros disponibles
                     </Link>
                  </li>
               </ul>
            </div>
         </nav>
         {/* <Routes /> */}
      </section>
   );
}
let mapState = (state) => {
   return {};
};

let mapDispatch = (dispatch) => {
   return {};
};

export default connect(mapState, mapDispatch)(NavSub);
