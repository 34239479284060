/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import Nav from "../base/Nav";
import Header from "../base/Header";
import NavSub1 from "../base/NavSub1";
import Footer from "../base/Footer";
import { connect } from "react-redux";
import { resetFichasAction } from "../../Redux/fichasDuck";

function Avistamientos({ resetFichasAction }) {
   useEffect(() => {
      resetFichasAction();
   }, []);
   return (
      <div className='content-principal  content-internas'>
         <header className=' internas-h '>
            <Nav />
            {/* <Header /> */}
         </header>
         <div className='content-principal  content-internas'>
            <header className=' internas-h '>
               <section className='titseccion bg-titleseccion'>
                  <div
                     className='container-xl h-100 d-flex align-items-center'
                     style={{ flexWrap: "wrap" }}
                  >
                     <div className='col-12 col-sm-6 col-md-6'>
                        <h2 className='title-modulo'>
                           Avistamientos - Tiburones, Rayas y Quimeras
                        </h2>
                     </div>
                     <div className='col-12 col-sm-6 col-md-6 d-flex justify-content-end'>
                        <a href='https://minambiente.gov.co'>
                           <img
                              src='.http://www.invemar.org.co/Invemar3.0-theme/images/img/logos/min-ambiente-gov.jpg'
                              width='240px'
                              alt=''
                           />
                        </a>
                     </div>
                  </div>
               </section>
            </header>
            <NavSub1 />

            <section className='contents-interna sig interna-componente'>
               <div className='container-xl my-3'>
                  <div className='row'>
                     <div className='col-md-12 mb-3'>
                        <p>&nbsp;</p>
                     </div>
                     <div className='col-md-6'>
                        <h3>Registrar Avistamientos</h3>
                        <p className='text-justify'>
                           Espacio para que la ciudadanía (buzos, turistas,
                           residentes, comunidad en general) aporte al
                           conocimiento de los tiburones, rayas y quimeras,
                           documentando avistamientos de las diferentes especies
                           en el país y que esta información, sea empleada en la
                           toma de decisiones en las políticas de protección de
                           ecosistemas en el territorio nacional.
                           <a
                              href='https://www.inaturalist.org/projects/tiburones-rayas-y-quimeras-de-colombia'
                              className='text-primary'
                              target="_blank"
                           >
                              Haz tu registro!
                           </a>
                        </p>
                        <p>&nbsp;</p>
                     </div>
                     <div className='col-md-6'>
                        <img
                           className='img-fluid'
                           src='./static/images/componente-t-t/Observaciones_mapa@2x.jpg'
                           alt=''
                        />
                     </div>
                  </div>
               </div>
            </section>
         </div>
         <Footer />
      </div>
   );
}
// sacar los state de (store) que tiene redux y los coloque en las props de este componente.
let mapState = (state) => {
   return {};
};
// sacar los dispatch de (store) que tiene redux y los coloque en las props de este componente.
const mapDispatch = (dispatch) => {
   return {
      resetFichasAction: () => dispatch(resetFichasAction()),
   };
};

// connect(mapState,mapDispatch)(this funtion)
export default connect(mapState, mapDispatch)(Avistamientos);
