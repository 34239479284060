import React, { useState, useEffect } from "react";
import Nav from ".././base/Nav";
import Navsub1 from ".././base/NavSub1";
import Footer from ".././base/Footer";
import { useHistory } from "react-router-dom";
import HeaderVisor from ".././base/HeaderVisor";
import { URL_APP } from "../../Routes";

import {
   MapContainer,
   MapConsumer,
   TileLayer,
   Marker,
   Popup,
} from "react-leaflet";
import "../../../node_modules/leaflet/dist/leaflet.css";
import { connect } from "react-redux";
import { getObservacionesAction } from "../../Redux/naturalistaDuck";
import { resetFichasAction } from "../../Redux/fichasDuck";

import Loader from "react-loader-spinner";
import "leaflet-draw";
import "../../../node_modules/leaflet-draw/dist/leaflet.draw.css";
import L from "leaflet";
import Progress from "../base/Progress";
var editableLayers = new L.FeatureGroup();
// const iAm = L.icon({
//    iconSize: [41, 41],
//    iconAnchor: [10, 41],
//    popupAnchor: [2, -40],
//    shadowAnchor: [0, 41],
//    iconUrl: "static/images/icons/pinAmarillo.png",
//    shadowUrl: "https://unpkg.com/leaflet@1.7/dist/images/marker-shadow.png",
// });
// const iRojo = L.icon({
//    iconSize: [41, 41],
//    iconAnchor: [10, 41],
//    popupAnchor: [2, -40],
//    shadowAnchor: [0, 41],
//    iconUrl: "static/images/icons/pinRojo.png",
//    shadowUrl: "https://unpkg.com/leaflet@1.7/dist/images/marker-shadow.png",
// });
const iAzul = L.icon({
   iconSize: [41, 41],
   iconAnchor: [10, 41],
   popupAnchor: [2, -40],
   shadowAnchor: [0, 41],
   iconUrl: "static/images/icons/pinAzul.png",
   shadowUrl: "https://unpkg.com/leaflet@1.7/dist/images/marker-shadow.png",
});
const iVerde = L.icon({
   iconSize: [41, 41],
   iconAnchor: [10, 41],
   popupAnchor: [2, -40],
   shadowAnchor: [0, 41],
   iconUrl: "static/images/icons/pinVerde.png",
   shadowUrl: "https://unpkg.com/leaflet@1.7/dist/images/marker-shadow.png",
});
// const icon3 = L.icon(className="fas fa-map-marker-alt");
const VisorMapa = ({ arrary_observaciones, filtros }) => {
   const history = useHistory();
   let url = URL_APP();

   // console.log(arrary_observaciones);

   const [edLayer, setEdLayer] = useState("");
   var observaciones = "0";
   const [list, setList] = useState([]);

   if (edLayer !== "") {
      var buff = [];
      buff = list.filter((value) =>
         edLayer.contains({
            lat: value.location.split(",")[0],
            lng: value.location.split(",")[1],
         })
      );
      console.log(buff);
      observaciones = buff;
   }

   useEffect(() => {
      // console.log(list1);
      // console.log(list);
      if (filtros.avistamientos === "") {
         setList(
            arrary_observaciones.map((observaciones) => {
               return {
                  ...observaciones,
                  lat: observaciones.geojson.coordinates[1],
                  long: observaciones.geojson.coordinates[0],
               };
            })
         );
      }
      if (filtros.avistamientos !== "") {
         var buff = arrary_observaciones;
         if (filtros.avistamientos.inv) {
            buff = buff.filter(
               (value) => value.user.id === filtros.avistamientos.inv
            );
         }
         if (filtros.avistamientos.Fini) {
            buff = buff.filter(
               (value) => value.observed_on > filtros.avistamientos.Fini
            );
         }
         if (filtros.avistamientos.Ffin) {
            buff.filter(
               (value) => value.observed_on < filtros.avistamientos.Ffin
            );
         }
         setList(buff);
      }
   }, [filtros, arrary_observaciones]);

   return list.length > 0 ? (
      <>
         <MapContainer
            center={[11.2402975, -74.2284027]}
            zoom={8}
            style={{
               height: "80vh",
            }}
            whenCreated={(map) => {
               map.addLayer(editableLayers);
               var drawPluginOptions = {
                  position: "topright",
                  draw: {
                     polygon: false,
                     polyline: false,
                     circle: false, // Turns off this drawing tool
                     rectangle: true,
                     marker: false,
                     circlemarker: false,
                  },
                  edit: {
                     featureGroup: editableLayers, //REQUIRED!!
                     remove: true,
                  },
               };
               // Initialise the draw control and pass it the FeatureGroup of editable layers
               var drawControl = new L.Control.Draw(drawPluginOptions);
               map.addControl(drawControl);
               return <div id='map'></div>;
            }}
         >
            <div
               style={{
                  position: "absolute",
                  zIndex: "1500",
                  margin: "0px 50px",
               }}
            >
               <div className='float-left my-3 ml-3'>
                  <div className='btn-group' role='group'>
                     <a href='' className='btn btn-light f-14 active'>
                        <i className='fa fa-map-marker'></i> Mapa
                     </a>
                     <button
                        onClick={() =>
                           history.push(url.lista_consulta, {
                              observaciones:
                                 observaciones === "0" ? list : observaciones,
                           })
                        }
                        className='btn btn-light f-14'
                     >
                        <i className='fa fa-list'></i> Lista
                     </button>
                  </div>
                  <br />
                  <img
                     src='./static/images/LeyendaTiburones.png'
                     alt='...'
                     height='70px'
                  />
               </div>
            </div>

            <TileLayer
               attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
               url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
            />
            <MapConsumer>
               {(map) => {
                  map.on("draw:created", function (e) {
                     var layer = e.layer;
                     setEdLayer(layer._bounds);
                     console.log(e.layer._bounds);

                     editableLayers.addLayer(layer);
                  });

                  map.on("draw:deleted	", function (e) {
                     var layer = e.layer;
                     // console.log(e.layer._bounds);
                     setEdLayer("");
                     //  setObservaciones("0");
                     //  setMonitoreos("0");

                     editableLayers.removeLayer(layer);
                  });
                  return <div id='map'></div>;
               }}
            </MapConsumer>
            {observaciones !== "0"
               ? observaciones.map((value) => (
                    <Marker
                       position={[
                          value.location.split(",")[0],
                          value.location.split(",")[1],
                       ]}
                       icon={iAzul}
                    >
                       <Popup>
                          {value ? (
                             <div>
                                {value.id}
                                <br />
                                <span>{value.species_guess}</span>
                                <br />
                                <a href={value.uri}>{value.uri}</a>

                                {/* <img src={value.photos[0].url} /> */}
                             </div>
                          ) : (
                             ""
                          )}
                          <br />
                       </Popup>
                    </Marker>
                 ))
               : list.map((value) => (
                    <Marker
                       position={[
                          value.location.split(",")[0],
                          value.location.split(",")[1],
                       ]}
                       icon={iVerde}
                    >
                       <Popup>
                          {value ? (
                             <div>
                                {value.id}
                                <br />
                                <span>{value.species_guess}</span>
                                <br />
                                <a href={value.uri}>{value.uri}</a>

                                <img
                                   src={
                                      value.photos[0]
                                         ? value.photos[0].url
                                         : value.taxon.default_photo.url
                                   }
                                />
                             </div>
                          ) : (
                             ""
                          )}
                          <br />
                       </Popup>
                    </Marker>
                 ))}
         </MapContainer>
      </>
   ) : (
      <div
         style={{
            width: "100%",
            height: "100",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
         }}
      >
         <Loader type='ThreeDots' color='#11273c' height='100' width='100' />
      </div>
   );
};

// funcion principal

function Visor({
   arrary_observaciones,
   getObservacionesAction,
   resetFichasAction,
}) {
   const [filtros, setFiltros] = useState({ avistamientos: "", monitoreo: "" });
   let url = URL_APP();

   const [avistamientosF, setAvistamientosF] = useState([]);

   useEffect(() => {
      resetFichasAction();
      getObservacionesAction();
      setAvistamientosF(arrary_observaciones);
   }, []);

   return (
      <div className='content-principal  content-internas'>
         <header className=' internas-h '>
            <Nav />
            {arrary_observaciones && (
               <HeaderVisor
                  filtrar={(data) => setFiltros(data)}
                  investigadores={arrary_observaciones}
               />
            )}
         </header>
         <section className='menubar'>
            <Navsub1  active = {true}/>
         </section>

         <section className=''>
            <div className='container-fluid p-0'>
               <div className='container-fluid p-0'>
                  {arrary_observaciones ? (
                     <VisorMapa
                        arrary_observaciones={arrary_observaciones}
                        filtros={filtros}
                     />
                  ): <Progress/>}
               </div>
            </div>
         </section>
         <Footer />
      </div>
   );
}
// sacar los state de (store) que tiene redux y los coloque en las props de este componente.
let mapState = (state) => {
   return {
      arrary_observaciones: state.naturalista.arrary_observaciones,
   };
};
// sacar los dispatch de (store) que tiene redux y los coloque en las props de este componente.
const mapDispatch = (dispatch) => {
   return {
      getObservacionesAction: (props) =>
         dispatch(getObservacionesAction(props)),
      resetFichasAction: () => dispatch(resetFichasAction()),
   };
};

// connect(mapState,mapDispatch)(this funtion)
export default connect(mapState, mapDispatch)(Visor);
