import axios from "axios";
//constantes
let initialState = {
   fetching_observaciones: false,
   error: "",
   arrary_observaciones: [],
};

let RESET_OBSERVACIONES = "RESET_OBSERVACIONES";

let GET_OBSERVACIONES = "GET_OBSERVACIONES";
let GET_OBSERVACIONES_SUCCESS = "GET_OBSERVACIONES_SUCCESS";
let GET_OBSERVACIONES_ERROR = "GET_OBSERVACIONES_ERRORS";

let URL_NATURALISTA =
   "https://api.inaturalist.org/v1/observations?project_id=tiburones-rayas-y-quimeras-de-colombia&per_page=200";
//Reducer

export default function reducer(state = initialState, action) {
   switch (action.type) {
      case RESET_OBSERVACIONES:
         return { ...initialState };

      //Referentes
      case GET_OBSERVACIONES:
         return {
            ...state,
            fetching_observaciones: true,
         };
      case GET_OBSERVACIONES_ERROR:
         return {
            ...state,
            fetching_observaciones: false,
            error: action.payload.error,
         };
      case GET_OBSERVACIONES_SUCCESS:
         return {
            ...state,
            fetching_observaciones: false,
            arrary_observaciones: action.payload.arrary_observaciones,
         };
      default:
         return state;
   }
}

//Action (action creator) --------------------------
// Action 1
export let resetObservacionesAction = () => (dispatch, getState) => {
   // Aqui la funcion para cerrar sesion en django
   dispatch({
      type: RESET_OBSERVACIONES,
   });
};

// Action 1 - get referentes
export let getObservacionesAction = () => (dispatch, getState) => {
   // fetching_referentes true para iniciar el consumo de la api

   dispatch({
      type: GET_OBSERVACIONES,
   });

   axios
      .get(URL_NATURALISTA, {
         crossdomain: true,
      })
      .then((res) => {
         dispatch({
            type: GET_OBSERVACIONES_SUCCESS,
            payload: {
               arrary_observaciones: res.data.results,
            },
         });
         // saveStorage(getState());
      })
      .catch((error) => {
         console.log("referentes", error);
         dispatch({
            type: GET_OBSERVACIONES_ERROR,
            payload: {
               error: error.response,
            },
         });
      });
};

// aux 1
function saveStorage(storage) {
   localStorage.storage = JSON.stringify(storage);
}

//aux 2
export let restoreNaturalistaAction = () => (dispatch, getState) => {
   let storage = localStorage.getItem("storage");
   storage = JSON.parse(storage);
   if (storage) {
      dispatch({
         type: GET_OBSERVACIONES_SUCCESS,
         payload: {
            arrary_observaciones: storage.naturalista.arrary_observaciones,
         },
      });
   }
};
