import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { URL_APP } from '../../Routes'
import { useHistory, Redirect, Link } from 'react-router-dom'
import Footer from '.././base/Footer'

import { getAllFichaAction, setFichaAction } from '../../Redux/fichasDuck'
import { resetObservacionesAction } from '../../Redux/naturalistaDuck'

function Home({ resetObservacionesAction, isFichas, getAllFichaAction, setFichaAction, arrary_fichas }) {
  let url = URL_APP()
  const history = useHistory()

  useEffect(() => {
    resetObservacionesAction()
    getAllFichaAction()
    setFichaAction([])
  }, [])

  let IrFicha = (ficha) => {
    // console.log(ficha)
    setFichaAction(ficha)
    history.push('/ficha-especie')
  }

  let ir_avistamiento = () => {
    history.push(url.avistamiento)
  }

  return (
    <div>
      <div class='content-principal bg-home'>
        <div class='temporal-cerar'></div>

        <header class='home-h'>
          <div class='logo-invemar-mobile'>
            <a title='invemar' href='http://www.invemar.org.co/'>
              <img src='./static/images/logo-invemar.png' alt='invemar' />
            </a>
          </div>
          <div class='menu-mobil' id='showMenu'>
            <a href='javascript:void(0)'>
              <i class='fa fa-bars font-menu-mobil'></i>
            </a>
          </div>
          <nav className='nav-principal nav-interiores' id='nav_principal'>
            <div className='btn-hide-menu' id='hideMenuInt'>
              <a href='javascript:void(0)'>
                <img src='static/images/btn-cerrar.png' alt='' />
                Cerrar
              </a>
            </div>
            <ul>
              <li>
                <i className='fa fa-home margin-icon-1 font-size-20'></i>
                <a href='/' target=''>
                  Inicio
                </a>
              </li>
              <li>
                <a href='https://siam.invemar.org.co/descripcion' target=''>
                  ¿Qué es SiAM?
                </a>
              </li>
              <li>
                <a href='https://siam.invemar.org.co/cifras_siam' target=''>
                  Cifras SIAM
                </a>
              </li>

              <li>
                <a href='https://siam.invemar.org.co/mapa-sitio' target=''>
                  Mapa sitio
                </a>
              </li>
              <li>
                <a href='http://www.invemar.org.co/web/guest/acuerdo-de-acceso-uso-a-datos' target='_blank'>
                  Solicitud de información
                </a>
              </li>
              <li>
                <a href='https://siam.invemar.org.co/noticias' target=''>
                  Noticias
                </a>
              </li>
              <li className='targetli'>
                <a href='javascript:void(0)'>Herramientas</a>
                <div className='submenu'>
                  <div className='submenu-item'>
                    <a href='javascript:void(0)'>Transversales</a>
                    <ul className='submenusub'>
                      <li>
                        <a href='https://siam.invemar.org.co/informacion-geografica' target=''>
                          Sección de Información Geográfica
                        </a>
                      </li>

                      <li>
                        <a href='https://siam.invemar.org.co/documentos' target=''>
                          Recursos Centro Documentación
                        </a>
                      </li>

                      <li>
                        <a href='https://siam.invemar.org.co/galerias' target=''>
                          Galerías
                        </a>
                      </li>

                      <li>
                        <a href='https://siam.invemar.org.co/herramientas' target=''>
                          Herramientas
                        </a>
                      </li>

                      <li>
                        <a href='https://siam.invemar.org.co/campanas-proyectos' target=''>
                          Proyectos y Estaciones
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
              <li>
                <a href='https://siam.invemar.org.co/contactenos' target=''>
                  Contáctenos
                </a>
              </li>
            </ul>
            <ul className='nav-ayudas'>
              <li>
                <a href='https://siam.invemar.org.co/buscador'>
                  <i className='fa fa-search' title='Buscar'></i>
                </a>
              </li>
              {/* <li>
                  <a
                     href="http://ayudas.invemar.org.co/web/siam"
                     target="_blank"
                  >
                     <i className="fa fa-question-circle" title="Ayuda"></i>
                  </a>
               </li> */}
              <li>
                <a href='https://siam.invemar.org.co/informacion-geografica'>
                  <i className='fa fa-globe' title='Información Geográfica'></i>
                </a>
              </li>
            </ul>
          </nav>

          <div class='clear-1'></div>
        </header>

        <section class='resume-home'>
          <h1>
            Tiburones, Rayas y Quimeras en Colombia
            <img class='logo-ma-portada' src='http://www.invemar.org.co/Invemar3.0-theme/images/img/logos/minambiente.png' alt='' />
          </h1>
          {isFichas && arrary_fichas.map((ficha) => <p>{ficha}</p>)}
          <p align='justify' class='f-14'>
            En este portal se podrá hallar información relacionada con las características biológicas de los peces cartilaginosos en Colombia. Su identificación, distribución,
            amenazas e iniciativas de conservación. Además, por medio de el reporte de avistamientos, los ciudadanos contribuirán a conocer y proteger los Tiburones, Rayas y
            Quimeras de los océanos colombianos.
          </p>
          <a href='#s1' class='botong boton-vermas'>
            Saber más
          </a>

          <Link className='botong boton-vermas' exact to={url.visor}>
            Consultar registros disponibles
          </Link>

          <br />
          <br />
          {/* <a href="javascript:void(0)" onClick={ir_avistamiento} class='botong boton-vermas'>
                  Registrar avistamiento
               </a> */}
        </section>

        {/* <div class='buscador-home'>
               <div class='buscador-home-int'>
                  <form
                     id='siam_searcher_alphanumeric'
                     method='POST'
                     action='/buscador'
                  >
                     <input
                        type='hidden'
                        name='csrfmiddlewaretoken'
                        value='qPxSeqY4R9Bi3QUrk6MSvUAL5yfa98SuXEoNlJoG6DEgiQCKgDp1uhaFmsBj43gZ'
                     />
                     <input
                        id='id_q'
                        maxlength='200'
                        name='q'
                        placeholder='Buscador SiAM'
                        type='text'
                     />
                     <div class='icon-buscador'>
                        <a id='siam_searcher_search_alphanumeric' href='javascript:void(0)'>
                           <i class='fa fa-search'></i>
                        </a>
                     </div>
                  </form>
               </div>
            </div> */}

        <section class='btn-inicio-siam'>
          <nav class='nav-siam'>
            <ul>
              <li class='nav-siam-item col3'>
                <a href='#especies' target='_self'>
                  <span>
                    <div class='icon-nav-siam'>
                      <img src='./static/images/tiburones/iconos-tiburon@2x.png' alt='' />
                    </div>
                    <span>
                      <h5>Especies prioritarias para la conservación en Colombia</h5>
                      <p>Ver listado</p>
                    </span>
                  </span>
                </a>
              </li>

              <li class='nav-siam-item col3'>
                <a href='javascript:void(0)' onClick={ir_avistamiento} target='_self'>
                  <span>
                    <div class='icon-nav-siam'>
                      <img src='./static/images/tiburones/iconos-tiburonBallena@2x.png' alt='' />
                    </div>
                    <span>
                      <h5>¿Has visto un Tiburón o Raya?</h5>
                      <p>Reportala aquí</p>
                    </span>
                  </span>
                </a>
              </li>
              <li class='nav-siam-item col3'>
                <a href='https://n2t.net/ark:/81239/m93m5c' target='_blank'>
                  <span>
                    <div class='icon-nav-siam'>
                      <img src='./static/images/menu-left/catalogo.png' alt='' />
                    </div>

                    <span>
                      <h5> Manual de usuario</h5>
                      <p>Manual que describe el funcionamiento de este portal</p>
                    </span>
                  </span>
                </a>
              </li>
            </ul>
          </nav>
        </section>
      </div>

      <section class='content-componente-t-t content-blank'>
        <nav class='navbar navbar-expand-lg sticky-top navbar-dark bg-dark'>
          <a class='navbar-brand' title='invemar' href='javascript:void(0)'>
            <img src='./static/images/logo-invemar.png' alt='invemar' />
            Tiburones, Rayas y Quimeras
          </a>
          <button
            class='navbar-toggler'
            type='button'
            data-toggle='collapse'
            data-target='#navbarSupportedContent'
            aria-controls='navbarSupportedContent'
            aria-expanded='false'
            aria-label='Toggle navigation'
          >
            <span class='navbar-toggler-icon'></span>
          </button>

          <div class='collapse navbar-collapse' id='navbarSupportedContent'>
            <ul class='navbar-nav ml-auto'>
              <li class='nav-item active'>
                <a class='nav-link' href='#s1'>
                  Generalidades <span class='sr-only'>(current)</span>
                </a>
              </li>
              <li class='nav-item'>
                <a class='nav-link' href='#s2'>
                  Historia
                </a>
              </li>
              <li class='nav-item'>
                <a class='nav-link' href='#s3'>
                  ¿Dónde hallarlos?
                </a>
              </li>
              <li class='nav-item'>
                <a class='nav-link' href='#s4'>
                  Anatomía
                </a>
              </li>
              <li class='nav-item'>
                <a class='nav-link' href='#s5'>
                  Identifícalos
                </a>
              </li>
              <li class='nav-item'>
                <a class='nav-link' href='#s6'>
                  Conservación
                </a>
              </li>
              <li class='nav-item'>
                <a class='nav-link' href='#especies'>
                  Especies prioritarias
                </a>
              </li>
              <li class='nav-item'>
                <a className='nav-link' href={url.avistamiento}>
                  Avistamiento
                </a>
              </li>
              {/* <li class='nav-item'>
                        <a class='nav-link' href= "https://n2t.net/ark:/81239/m93m5c" target="_blank" >
                           Manula de usuario
                        </a>
                     </li> */}
            </ul>
          </div>
        </nav>

        <section id='s1' class='general'>
          <div class='container'>
            <div class='row'>
              <div class='col-md-12 mb-5 mt-2'>
                <h2 class='title-sec-comp'>Generalidades</h2>
                <p class='text-justify'>
                  Los peces cartilaginosos o Condrictios (chondros= cartílago e ichthys = pez) es un grupo monofilético (Cunha et al., 2017), que presenta 1192 especies en el mundo
                  (Stein, 2018). La clase Condrictios se compone de dos subclases, Holocephali (Quimeras) y Elasmobranchii (Tiburones y Rayas), e incluye 14 órdenes, 60 familias,
                  198 géneros.{' '}
                </p>
                <p class='text-justify'>
                  La mayor diferencia entre estos, y otros peces, es que su esqueleto está formado por tejido cartilaginoso. A esta característica, se suman una serie de
                  adaptaciones sorprendentes que permiten evidenciar que son un grupo único y carismático entre todos los seres vivos. Por su rol de depredadores tope en la cadena
                  alimenticia, son de vital importancia para la conservación de los ecosistemas marinos.
                </p>
              </div>
            </div>
          </div>
          <div class='container-fluid'>
            <div class='row d-flex align-items-center'>
              <div class='col-md-5 pl-0'>
                <div class='img-tiburon'></div>
              </div>
              <div class='col-md-6' id='s2'>
                <div class='container'>
                  <h2 class='title-sec-comp'>
                    Historia Natural:
                    <br /> Más antiguos que los dinosaurios..{' '}
                  </h2>
                  <p class='text-justify'>
                    Los Condrictios habitan el planeta tierra incluso más de 20 millones de años antes que los dinosaurios y sobrevivieron a la mayor extinción masiva en el
                    Pérmico-Triásico. Se sabe que evolucionaron a partir de un antepasado que originalmente tenía hueso en su esqueleto ya que existe la hipótesis de que la pérdida
                    de genes formadores de huesos se produjo en la línea evolutiva que conduce a los peces cartilaginosos (Frey et al., 2019).
                  </p>
                  <p class='text-justify'>
                    El origen de los condrictios fue hace 450 m.a. (Ordovícico), y hace 380 m.a. (Devónico) se originaron los primeros Elasmobranquios. El apogeo de su evolución se
                    dio en el Paleozoico tardío (hace ~250 m.a.) cuando ocurrió la declinación de los placodermos (Frey et al., 2019).
                  </p>
                </div>
              </div>
              <div class='container'>
                <div class='row'>
                  <div class='col-md-12'>
                    <p class='text-justify'>
                      Las mandíbulas de los condrictios son cartílagos provistos de varias filas de dientes que se reemplazan a lo largo de su vida (Escamas modificadas). Debido a
                      que el cartílago se descompone fácilmente, es poco frecuente encontrar fósiles de su esqueleto. Por lo anterior, la identificación y registro fósil está
                      representando mayormente por los dientes. Los dientes más antiguos hallados pertenecen al género <i>Leonodus sp</i>. En el Devónico bajo (Mader, 1986)
                    </p>
                    <p class='text-center'>
                      <img class='img-fluid' src='./static/images/tiburones/periodosHistoricos.jpg' alt='' />
                    </p>
                    <cite></cite>
                    <p class='text-justify'>
                      En Colombia, los Condrictios más antiguos documentados vivieron en el periodo Devónico medio (hace 410 m.a.) y pertenecen al orden Xenacanthida, los cuales
                      eran anguiliformes, con la aleta dorsal extendida de la cabeza a la cola y dos aletas anales. Los restos de éstos tiburones se han encontrado asociados a
                      depósitos de agua dulce. También se han hallado en depósitos de rocas de la región amazónica, Condrictios pertenecientes a la era Mesozoica de los grupos
                      Galeomorphii, Ptychodontidae y Sclerorhynchidae (Rajiformes) los cuales ya están extintos (Álvarez-León et al., 2013).
                    </p>
                    <p class='text-justify'></p>
                  </div>
                </div>
                <div class='row'>
                  <div class='col-md-6'>
                    <p class='text-center'>
                      <img class='img-fluid w-75' src='./static/images/tiburones/primeros-condrictios.jpg' alt='' />
                      <br />
                      <cite>Primeros Condrictios de Colombia. Orden Xenacanthida. Tomado de https://chondrichthyes.fandom.com/wiki/Xenacanthida. Acceso 27 Nov. 2020 </cite>
                    </p>
                  </div>
                  <div class='col-md-6'>
                    <p class='text-center'>
                      <img class='img-fluid w-50' src='./static/images/tiburones/diente-mega.jpg' alt='' />
                      <br />
                      <cite>Diente de Megalodon Tomado de https://pixabay.com/es/photos/diente-fosilizado-megalodon-1162531/Acceso 27 Nov. 2020</cite>
                    </p>
                  </div>
                </div>
                <hr />
              </div>
            </div>
          </div>
        </section>

        <section id='s3' class='hallarlos'>
          <div class='container'>
            <div class='row '>
              <div class='col-md-12'>
                <h2 class='title-sec-comp'>¿Dónde se hallan los peces cartilaginosos?</h2>
                <p class='text-justify'>
                  Se hallan en todos los océanos del planeta. Todos son marinos a excepción de algunas Rayas que viven en agua dulce (Familia Potamotrygonidae). La diversidad de
                  especies de condrictios suele ser mayor en aguas cálidas y someras.{' '}
                </p>
              </div>
              <div class='col-md-5'>
                <img class='img-fluid w-100' src='./static/images/tiburones/tib-ray-qui-dg.jpg' alt='' />
                <cite>
                  Distribución global de Tiburones, Rayas y Quimeras. Donde se observa mayor coloración hay más riqueza de especies. Modificado de aquamaps.org y Bermis (2016)
                </cite>
              </div>
              <div class='col-md-7'>
                <p class='text-justify'>
                  Cuando pensamos en los hábitats de los tiburones, nos vienen a la mente varios factores. Primero, debemos considerar dónde viven los tiburones con respecto a las
                  estructuras del entorno, como las características del fondo, los arrecifes o la propia columna de agua. Usamos cuatro términos para describir las asociaciones
                  entre tiburones y hábitat: pelágico, bentónico, epibentónico y asociado a arrecifes. Los tiburones de hábitos bentónicos tienen un rango geográfico relativamente
                  pequeño en comparación con los tiburones que son pelágicos. Muchas especies pelágicas se distribuyen en cuencas oceánicas enteras y migran dependiendo de la
                  temperatura del agua y de la disponibilidad de alimento. Por ejemplo, Los datos de seguimiento recientes indican que los tiburones tigre, <i>Galeocerdo cuvier</i>
                  , realizan migraciones de ida y vuelta de más de 7.500 km (4.660 millas), moviéndose entre los arrecifes de coral del Caribe en invierno y áreas de mar abierto de
                  alta latitud en verano.{' '}
                </p>
              </div>
              <div class='col-md-12 mt-5'>
                <p class='text-justify'>
                  A nivel nacional y de acuerdo con estudios recientes de investigadores de Wildlife Conservation Society y la Fundación Squalus (Mejia & Navia, 2019), en Colombia
                  hay 76 especies de tiburones y 62 de rayas que pudieron confirmarse a partir de registros de museos, capturas, fotografías y videos. Esto representa una adición
                  de 26 especies confirmadas para Colombia en comparación con las que se conocían en 2007 (Mejía-Falla et al., 2007). Estos valores equivalen al 12% de las especies
                  de condrictios conocidas a escala mundial, lo que indica que Colombia tiene una de las faunas de peces cartilaginosos más ricos de América Latina, detrás de
                  México y Brasil. Dado lo anterior es importante que, de manera interinstitucional y liderado por el Ministerio de Ambiente y Desarrollo Sostenible, se fortalezcan
                  las medidas de conservación sobre estos peces en Colombia.
                </p>
              </div>
            </div>
            <hr />
          </div>
        </section>

        <section id='s4' class='anatomia mb-5'>
          <div class='container'>
            <h2 class='title-sec-comp'>¡Anatomía única!</h2>
            <div class='row'>
              <div class='container'>
                <div class='col-md-12'>
                  <p class='text-justify'>
                    Los Tiburones, Rayas y Quimeras presentan distintas formas anatómicas que les permiten habitar en determinado ambiente. Algunas formas son ideales para cazar y
                    hacer grandes migraciones, mientras que otras formas les permiten protegerse de otros depredadores y pasar desapercibidos. A continuación se observan las
                    características externas de los tiburones, rayas y quimeras.
                  </p>
                </div>
                <div class='row d-flex align-items-center' styles={{ flexWrap: 'wrap' }}>
                  <div class='col-md-8'>
                    <p class='text-justify'>
                      <img class='img-fluid w-100' src='./static/images/tiburones/tiburon-cd.jpg' alt='' />
                    </p>
                  </div>
                  <div class='col-md-4'>
                    <p class='text-justify'>
                      Los tiburones presentan aberturas branquiales que no están fusionadas con las aletas pectorales (excepto familia Squatinidae). Los ojos se situan
                      lateralmente. La boca se ubica posición subterminal generalmente. Están cubiertos por escamas placoideas que les confieren propiedades hidrodinámicas. Las
                      aletas pectorales las usan para nadar y tener estabilidad. Presentan aletas pélvicas, aleta anal y aleta caudal .
                    </p>
                  </div>
                </div>
              </div>

              <div class='container'>
                <div class='row d-flex align-items-center' styles={{ flexWrap: 'wrap' }}>
                  <div class='col-md-5'>
                    <p class='text-justify'>
                      Las Rayas (Batoidea) presentan un cuerpo totalmente aplanado. Las aletas pectorales están unidas a la cabeza y una cola más delgada que el tronco. Presentan
                      cinco hendiduras branquiales (excepto fam. Hexatrigonidae). No presentan aleta anal. La piel es lisa y algunas tienen aguijones venenosos. No tienen membrana
                      nictitante. Presentan espiráculos detrás de los ojos para ingerir agua. La boca se ubica en posición ventral o anterior en Mantarrayas. Presentan dientes en
                      forma de mosaico.
                    </p>
                  </div>
                  <div class='col-md-7'>
                    <p class='text-justify'>
                      <img class='img-fluid w-100' src='./static/images/tiburones/raya-cd.jpg' alt='' />
                      <cite>
                        Morfología externa de una Raya. Tomado de Guía para la identificación de especies de tiburones, rayas y quimeras de Colombia. Bogotá, D.C.: Colombia.
                        Ministerio de Ambiente y Desarrollo Sostenible; Corporación para el Desarrollo Sostenible del Archipiélago de San Andrés, Providencia y Santa Catalina –
                        CORALINA; Gobernación de San Andrés, Providencia y Santa Catalina, Fundación SQUALUS, 2011. p. 9
                      </cite>
                    </p>
                  </div>
                </div>
              </div>

              <div class='container'>
                <div class='row d-flex align-items-center' styles={{ flexWrap: 'wrap' }}>
                  <div class='col-md-6'>
                    <img class='img-fluid w-100' src='./static/images/tiburones/quimera-cd.jpg' alt='' />
                    <cite>
                      Morfología externa de una Quimera. Tomado de Guía para la identificación de especies de tiburones, rayas y quimeras de Colombia. Bogotá, D.C.: Colombia.
                      Ministerio de Ambiente y Desarrollo Sostenible; Corporación para el Desarrollo Sostenible del Archipiélago de San Andrés, Providencia y Santa Catalina –
                      CORALINA; Gobernación de San Andrés, Providencia y Santa Catalina, Fundación SQUALUS, 2011. p. 9
                    </cite>
                  </div>
                  <div class='col-md-6'>
                    <p class='text-justify'>
                      Las Quimeras tienen una cabeza prominente, con ojos grandes y un cuerpo alargado que termina en una cola en forma de látigo. Presentan piel elástica sin
                      dentículos (excepto en las crías). Tienen un opérculo carnoso que cubre las cuatro aberturas branquiales, no presentan espiráculo y presentan fuertes placas
                      dentales para consumir alimentos duros como invertebrados bentónicos.
                    </p>
                  </div>
                </div>
              </div>

              <hr />
            </div>

            <section id='s5' class='identificacion'>
              <div class='container'>
                <div class='row d-flex align-items-center'>
                  <div class='col-md-12'>
                    <h2 class='title-sec-comp'>¿Tiburón, Raya o Quimera?</h2>
                    <p class='text-justify'>
                      La clase Condrictios se divide en dos subclases: Elasmobranquii (Tiburones y Rayas) y Holocephalii (Quimeras). ¡Haz click sobre cada imagen y podrás
                      identificar las diferentes familias que habitan los océanos de Colombia!
                    </p>
                    <div class='d-flex justify-content-center mt-4 imgs-trq'>
                      <ul class='nav nav-tabs' id='myTab' role='tablist'>
                        <li class='col-md-4 nav-item'>
                          <a id='tiburones-tab' data-toggle='tab' href='#tiburones' role='tab' aria-controls='tiburones' aria-selected='false'>
                            <img src='./static/images/tiburones/fig-tiburon@2x.jpg' alt='' />
                            <p>Los Tiburones tienen forma de torpedo y una aleta dorsal pronunciada.</p>
                          </a>
                        </li>
                        <li class='col-md-4 nav-item'>
                          <a id='rayas-tab' data-toggle='tab' href='#rayas' role='tab' aria-controls='rayas' aria-selected='false'>
                            <img src='./static/images/tiburones/fig-raya@2x.jpg' alt='' />
                            <p>Las rayas y mantarrayas tienen cuerpo aplanado, con dos aletas pectorales unidas a la cabeza y una cola alargada.</p>
                          </a>
                        </li>
                        <li class='col-md-4 nav-item'>
                          <a id='quimeras-tab' data-toggle='tab' href='#quimeras' role='tab' aria-controls='quimeras' aria-selected='false'>
                            <img src='./static/images/tiburones/fig-quimera@2x.jpg' alt='' />
                            <p>Las Quimeras tienen un cuerpo alargado con cola en forma de látigo. Los ojos son grandes, y la piel carece de dentículos.</p>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div class='tab-content' id='myTabContent'>
                      <div class='tab-pane fade' id='tiburones' role='tabpanel' aria-labelledby='tiburones-tab'>
                        <div class='container'>
                          <div class='row'>
                            <div class='col-md-12 mt-3 text-center'>
                              <h6>Lamniformes</h6>
                            </div>
                            <div class='col-6 col-md-2'>
                              <a data-toggle='collapse' href='#collapseT1-1' role='button' aria-expanded='false' aria-controls='collapseT1-1'>
                                <img class='w-100' src='./static/images/tiburones/id/lamniformes/1.png' alt='' />
                                <p class='f-14' />
                              </a>
                              <div class='collapse' id='collapseT1-1'>
                                <div class='btn-group-vertical f-especie'>
                                  <b>Alopiidae</b>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Alopias pelagicus</i>
                                  </a>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Alopias superciliosus</i>
                                  </a>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Alopias vulpinus</i>
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div class='col-6 col-md-2'>
                              <a data-toggle='collapse' href='#collapseT1-2' role='button' aria-expanded='false' aria-controls='collapseT1-2'>
                                <img class='w-100' src='./static/images/tiburones/id/lamniformes/2.png' alt='' />
                                <p class='f-14' />
                              </a>
                              <div class='collapse' id='collapseT1-2'>
                                <div class='btn-group-vertical f-especie'>
                                  <b>Cetorhinidae</b>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Cetorhinus maximus</i>
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div class='col-6 col-md-2'>
                              <a data-toggle='collapse' href='#collapseT1-3' role='button' aria-expanded='false' aria-controls='collapseT1-3'>
                                <img class='w-100' src='./static/images/tiburones/id/lamniformes/3.png' alt='' />
                                <p class='f-14' />
                              </a>
                              <div class='collapse' id='collapseT1-3'>
                                <div class='btn-group-vertical f-especie'>
                                  <b>Pseudocarchariidae</b>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Pseudocarcharias kamoharai</i>
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div class='col-6 col-md-2'>
                              <a data-toggle='collapse' href='#collapseT1-4' role='button' aria-expanded='false' aria-controls='collapseT1-4'>
                                <img class='w-100' src='./static/images/tiburones/id/lamniformes/4.png' alt='' />
                                <p class='f-14' />
                              </a>
                              <div class='collapse' id='collapseT1-4'>
                                <div class='btn-group-vertical f-especie'>
                                  <b>Lamnidae</b>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Isurus oxyrinchus</i>
                                  </a>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Isurus paucus</i>
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div class='col-6 col-md-2'>
                              <a data-toggle='collapse' href='#collapseT1-5' role='button' aria-expanded='false' aria-controls='collapseT1-5'>
                                <img class='w-100' src='./static/images/tiburones/id/lamniformes/5.png' alt='' />
                                <p class='f-14' />
                              </a>
                              <div class='collapse' id='collapseT1-5'>
                                <div class='btn-group-vertical f-especie'>
                                  <b>Mitsukurinidae</b>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Mitsukurina owstoni</i>
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div class='col-6 col-md-2'>
                              <a data-toggle='collapse' href='#collapseT1-6' role='button' aria-expanded='false' aria-controls='collapseT1-6'>
                                <img class='w-100' src='./static/images/tiburones/id/lamniformes/6.png' alt='' />
                                <p class='f-14' />
                              </a>
                              <div class='collapse' id='collapseT1-6'>
                                <div class='btn-group-vertical f-especie'>
                                  <b>Odontaspidae</b>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Odontaspis ferox</i>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class='row'>
                            <div class='col-md-12 mt-3 text-center'>
                              <h6>Carcharhiniformes</h6>
                            </div>
                            <div class='col-6 col-md-3'>
                              <a data-toggle='collapse' href='#collapseT2-1' role='button' aria-expanded='false' aria-controls='collapseT2-1'>
                                <img class='w-75' src='./static/images/tiburones/id/carcharhiniformes/1.png' alt='' />
                                <p class='f-14' />
                              </a>
                              <div class='collapse' id='collapseT2-1'>
                                <div class='btn-group-vertical f-especie'>
                                  <b>Carcharhinidae</b>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Carcharhinus brevipinna</i>
                                  </a>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Carcharhinus falciformis</i>
                                  </a>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Carcharhinus galapagensis</i>
                                  </a>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Carcharhinus leucas</i>
                                  </a>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Carcharhinus limbatus</i>
                                  </a>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Carcharhinus obscurus</i>
                                  </a>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Carcharhinus perezii</i>
                                  </a>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Carcharhinus plumbeus</i>
                                  </a>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Carcharhinus porosus</i>
                                  </a>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Galeocerdo cuvier</i>
                                  </a>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Nasolamia velox</i>
                                  </a>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Negaprion brevirostris</i>
                                  </a>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Prionace glauca</i>
                                  </a>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Rhizoprionodon lalandii</i>
                                  </a>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Rhizoprionodon longurio</i>
                                  </a>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Rhizoprionodon porosus</i>
                                  </a>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Triaenodon obesus</i>
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div class='col-6 col-md-3'>
                              <a data-toggle='collapse' href='#collapseT2-2' role='button' aria-expanded='false' aria-controls='collapseT2-2'>
                                <img class='w-75' src='./static/images/tiburones/id/carcharhiniformes/2.png' alt='' />
                                <p class='f-14' />
                              </a>
                              <div class='collapse' id='collapseT2-2'>
                                <div class='btn-group-vertical f-especie'>
                                  <b>Scyliorhinidae</b>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Apristurus canutus</i>
                                  </a>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Apristurus nasutus</i>
                                  </a>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Apristurus parvipinnis</i>
                                  </a>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Apristurus riveri</i>
                                  </a>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Cephalurus cephalus</i>
                                  </a>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Galeus arae</i>
                                  </a>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Galeus cadenati</i>
                                  </a>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Schroederichthys maculatus</i>
                                  </a>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Scyliorhinus boa</i>
                                  </a>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Scyliorhinus haeckelii</i>
                                  </a>
                                  {/* <a
                                                      href='javascript:void(0)'
                                                      class='btn btn-light'
                                                   >
                                                      {" "}
                                                      <i>
                                                         Negaprion brevirostris
                                                      </i>
                                                   </a> */}
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Scyliorhinus hesperius</i>
                                  </a>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Scyliorhinus retifer</i>
                                  </a>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Scyliorhinus torrei</i>
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div class='col-6 col-md-3'>
                              <a data-toggle='collapse' href='#collapseT2-3' role='button' aria-expanded='false' aria-controls='collapseT2-3'>
                                <img class='w-75' src='./static/images/tiburones/id/carcharhiniformes/3.png' alt='' />
                                <p class='f-14' />
                              </a>
                              <div class='collapse' id='collapseT2-3'>
                                <div class='btn-group-vertical f-especie'>
                                  <b>Sphyrnidae</b>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Sphyrna corona</i>
                                  </a>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Sphyrna lewini</i>
                                  </a>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Sphyrna media</i>
                                  </a>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Sphyrna mokarran</i>
                                  </a>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Sphyrna tiburo</i>
                                  </a>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Sphyrna tudes</i>
                                  </a>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Sphyrna zygaena</i>
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div class='col-6 col-md-3'>
                              <a data-toggle='collapse' href='#collapseT2-4' role='button' aria-expanded='false' aria-controls='collapseT2-4'>
                                <img class='w-75' src='./static/images/tiburones/id/carcharhiniformes/4.png' alt='' />
                                <p class='f-14' />
                              </a>
                              <div class='collapse' id='collapseT2-4'>
                                <div class='btn-group-vertical f-especie'>
                                  <b>Triakidae</b>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Mustelus canis</i>
                                  </a>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Mustelus dorsalis</i>
                                  </a>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Mustelus henlei</i>
                                  </a>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Mustelus higmani</i>
                                  </a>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Mustelus lunulatus</i>
                                  </a>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Mustelus mento</i>
                                  </a>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Mustelus minicanis</i>
                                  </a>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Mustelus norrisi</i>
                                  </a>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Mustelus whitneyi</i>
                                  </a>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Triakis acutipinna</i>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class='row'>
                            <div class='col-md-12 mt-3 text-center'>
                              <h6>Squaliformes</h6>
                            </div>
                            <div class='col-6 col-md-3'>
                              <a data-toggle='collapse' href='#collapseT3-1' role='button' aria-expanded='false' aria-controls='collapseT3-1'>
                                <img class='w-75' src='./static/images/tiburones/id/squaliformes/1.png' alt='' />
                                <p class='f-14' />
                              </a>
                              <div class='collapse' id='collapseT3-1'>
                                <div class='btn-group-vertical f-especie'>
                                  <b>Centrophoridae</b>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Centrophorus granulosus</i>
                                  </a>
                                  <b>Dalatidae</b>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Isistius brasiliensis</i>
                                  </a>
                                  <b>Etmopteridae</b>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Centroscyllium nigrum</i>
                                  </a>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Etmopterus bullisi</i>
                                  </a>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Etmopterus carteri</i>
                                  </a>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Etmopterus gracilispinis</i>
                                  </a>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Etmopterus hillianus</i>
                                  </a>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Etmopterus perryi</i>
                                  </a>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Etmopterus robinsi</i>
                                  </a>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Etmopterus schultzi</i>
                                  </a>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Etmopterus virens</i>
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div class='col-6 col-md-3'>
                              <a data-toggle='collapse' href='#collapseT3-2' role='button' aria-expanded='false' aria-controls='collapseT3-2'>
                                <img class='w-75' src='./static/images/tiburones/id/squaliformes/2.png' alt='' />
                                <p class='f-14' />
                              </a>
                              <div class='collapse' id='collapseT3-2'>
                                <div class='btn-group-vertical f-especie'>
                                  <b>Oxynotidae</b>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Oxynotus caribbaeus</i>
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div class='col-6 col-md-3'>
                              <a data-toggle='collapse' href='#collapseT3-3' role='button' aria-expanded='false' aria-controls='collapseT3-3'>
                                <img class='w-75' src='./static/images/tiburones/id/squaliformes/3.png' alt='' />
                                <p class='f-14' />
                              </a>
                              <div class='collapse' id='collapseT3-3'>
                                <div class='btn-group-vertical f-especie'>
                                  <b>Somniosidae</b>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Somniosus sp.</i>
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div class='col-6 col-md-3'>
                              <a data-toggle='collapse' href='#collapseT3-4' role='button' aria-expanded='false' aria-controls='collapseT3-4'>
                                <img class='w-75' src='./static/images/tiburones/id/squaliformes/4.png' alt='' />
                                <p class='f-14' />
                              </a>
                              <div class='collapse' id='collapseT3-4'>
                                <div class='btn-group-vertical f-especie'>
                                  <b>Squalidae</b>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Squalus cubensis</i>
                                  </a>
                                  {/* <a
                                                      href='javascript:void(0)'
                                                      class='btn btn-light'
                                                   >
                                                      {" "}
                                                      <i>Squatina david</i>
                                                   </a> */}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class='row'>
                            <div class='col-12 col-md-6'>
                              <div class='col-md-12 mt-3 text-center'>
                                <h6>Orectolobiformes</h6>
                              </div>
                              <div class='row'>
                                <div class='col-6 col-md-6'>
                                  <a data-toggle='collapse' href='#collapseT4-1' role='button' aria-expanded='false' aria-controls='collapseT4-1'>
                                    <img class='w-75' src='./static/images/tiburones/id/orectolobiformes/1.png' alt='' />
                                    <p class='f-14' />
                                  </a>
                                  <div class='collapse' id='collapseT4-1'>
                                    <div class='btn-group-vertical f-especie'>
                                      <b>Ginglymostomatidae</b>
                                      <a href='javascript:void(0)' class='btn btn-light'>
                                        {' '}
                                        <i>Ginglymostoma cirratum</i>
                                      </a>
                                      <a href='javascript:void(0)' class='btn btn-light'>
                                        {' '}
                                        <i>Ginglymostoma unami</i>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                                <div class='col-6 col-md-6'>
                                  <a data-toggle='collapse' href='#collapseT4-2' role='button' aria-expanded='false' aria-controls='collapseT4-2'>
                                    <img class='w-75' src='./static/images/tiburones/id/orectolobiformes/2.png' alt='' />
                                    <p class='f-14' />
                                  </a>
                                  <div class='collapse' id='collapseT4-2'>
                                    <div class='btn-group-vertical f-especie'>
                                      <b>Rhincodontidae</b>
                                      <a href='javascript:void(0)' class='btn btn-light'>
                                        {' '}
                                        <i>Rhincodon typus</i>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class='col-6 col-md-3'>
                              <div class='col-md-12 mt-3'>
                                <h6>Echinorhiniformes</h6>
                              </div>
                              <div class=''>
                                <div class='col-12 col-md-12'>
                                  <a data-toggle='collapse' href='#collapseT5-1' role='button' aria-expanded='false' aria-controls='collapseT5-1'>
                                    <img class='w-75' src='./static/images/tiburones/id/echinorhiniformes/1.png' alt='' />
                                    <p class='f-14' />
                                  </a>
                                  <div class='collapse' id='collapseT5-1'>
                                    <div class='btn-group-vertical f-especie'>
                                      <b>Echinorhinidae</b>
                                      <a href='javascript:void(0)' class='btn btn-light'>
                                        {' '}
                                        <i>Echinorhinus brucus</i>
                                      </a>
                                      <a href='javascript:void(0)' class='btn btn-light'>
                                        {' '}
                                        <i>Echinorhinus cookei</i>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class='col-6 col-md-3'>
                              <div class='col-md-12 mt-3'>
                                <h6>Hexanchiformes</h6>
                              </div>
                              <div class='row'>
                                <div class='col-12 col-md-12'>
                                  <a data-toggle='collapse' href='#collapseT6-1' role='button' aria-expanded='false' aria-controls='collapseT6-1'>
                                    <img class='w-75' src='./static/images/tiburones/id/hexanchiformes/1.png' alt='' />
                                    <p class='f-14' />
                                  </a>
                                  <div class='collapse' id='collapseT6-1'>
                                    <div class='btn-group-vertical f-especie'>
                                      <b>Hexanchidae</b>
                                      <a href='javascript:void(0)' class='btn btn-light'>
                                        {' '}
                                        <i>Heptranchias perlo</i>
                                      </a>
                                      <a href='javascript:void(0)' class='btn btn-light'>
                                        {' '}
                                        <i>Hexanchus griseus</i>
                                      </a>
                                      <a href='javascript:void(0)' class='btn btn-light'>
                                        {' '}
                                        <i>Hexanchus nakamurai</i>
                                      </a>
                                      <a href='javascript:void(0)' class='btn btn-light'>
                                        {' '}
                                        <i>Notorynchus cepedianus</i>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class='row'>
                            <div class='col-6 col-md-3'>
                              <div class='col-md-12 mt-3'>
                                <h6>Heterodontiformes</h6>
                              </div>
                              <div class=''>
                                <div class='col-12 col-md-12'>
                                  <a data-toggle='collapse' href='#collapseT7-1' role='button' aria-expanded='false' aria-controls='collapseT7-1'>
                                    <img class='w-75' src='./static/images/tiburones/id/heterodontiformes/1.png' alt='' />
                                    <p class='f-14' />
                                  </a>
                                  <div class='collapse' id='collapseT7-1'>
                                    <div class='btn-group-vertical f-especie'>
                                      <b>Heterodontidae</b>
                                      <a href='javascript:void(0)' class='btn btn-light'>
                                        {' '}
                                        <i>Heterodontus francisci</i>
                                      </a>
                                      <a href='javascript:void(0)' class='btn btn-light'>
                                        {' '}
                                        <i>Heterodontus mexicanus</i>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class='col-6 col-md-3'>
                              <div class='col-md-12 mt-3'>
                                <h6>Squatiniformes</h6>
                              </div>
                              <div class='row'>
                                <div class='col-12 col-md-12'>
                                  <a data-toggle='collapse' href='#collapseT8-1' role='button' aria-expanded='false' aria-controls='collapseT8-1'>
                                    <img class='w-75' src='./static/images/tiburones/id/squatiniformes/1.png' alt='' />
                                    <p class='f-14' />
                                  </a>
                                  <div class='collapse' id='collapseT8-1'>
                                    <div class='btn-group-vertical f-especie'>
                                      <b>Squatinidae</b>
                                      <a href='javascript:void(0)' class='btn btn-light'>
                                        {' '}
                                        <i>Squatina armata</i>
                                      </a>
                                      <a href='javascript:void(0)' class='btn btn-light'>
                                        {' '}
                                        <i>Squatina david</i>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class='tab-pane fade' id='rayas' role='tabpanel' aria-labelledby='rayas-tab'>
                        <div class='container'>
                          <div class='row'>
                            <div class='col-12 col-md-6'>
                              <div class='col-md-12 mt-3 text-center'>
                                <h6>Torpediniformes</h6>
                              </div>
                              <div class='row'>
                                <div class='col-6 col-md-6'>
                                  <a data-toggle='collapse' href='#collapseT8-1' role='button' aria-expanded='false' aria-controls='collapseT8-1'>
                                    <img class='w-75' src='./static/images/tiburones/id/torpediniformes/1.png' alt='' />
                                    <p class='f-14' />
                                  </a>
                                  <div class='collapse' id='collapseT8-1'>
                                    <div class='btn-group-vertical f-especie'>
                                      <b>Narcinidae</b>
                                      <a href='javascript:void(0)' class='btn btn-light'>
                                        {' '}
                                        <i>Diplobatis colombiensis</i>
                                      </a>
                                      <a href='javascript:void(0)' class='btn btn-light'>
                                        {' '}
                                        <i>Diplobatis guamachensis</i>
                                      </a>
                                      <a href='javascript:void(0)' class='btn btn-light'>
                                        {' '}
                                        <i>Diplobatis ommata</i>
                                      </a>
                                      <a href='javascript:void(0)' class='btn btn-light'>
                                        {' '}
                                        <i>Diplobatis picta</i>
                                      </a>
                                      <a href='javascript:void(0)' class='btn btn-light'>
                                        {' '}
                                        <i>Narcine bancroftii</i>
                                      </a>
                                      <a href='javascript:void(0)' class='btn btn-light'>
                                        {' '}
                                        <i>Narcine vermiculata</i>
                                      </a>
                                      <a href='javascript:void(0)' class='btn btn-light'>
                                        {' '}
                                        <i>Narcine entemedor</i>
                                      </a>
                                      <a href='javascript:void(0)' class='btn btn-light'>
                                        {' '}
                                        <i>Narcine leoparda</i>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                                <div class='col-6 col-md-6'>
                                  <a data-toggle='collapse' href='#collapseT8-2' role='button' aria-expanded='false' aria-controls='collapseT8-2'>
                                    <img class='w-75' src='./static/images/tiburones/id/torpediniformes/2.png' alt='' />
                                    <p class='f-14' />
                                  </a>
                                  <div class='collapse' id='collapseT8-2'>
                                    <div class='btn-group-vertical f-especie'>
                                      <b>Torpedinidae</b>
                                      <a href='javascript:void(0)' class='btn btn-light'>
                                        {' '}
                                        <i>Tetronarce nobiliana</i>
                                      </a>
                                      <a href='javascript:void(0)' class='btn btn-light'>
                                        {' '}
                                        <i>Tetronarce tremens</i>
                                      </a>
                                      <a href='javascript:void(0)' class='btn btn-light'>
                                        {' '}
                                        <i>Torpedo andersoni</i>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class='col-12 col-md-6'>
                              <div class='col-md-12 mt-3 text-center'>
                                <h6>Rajiformes</h6>
                              </div>
                              <div class='row'>
                                <div class='col-6 col-md-6'>
                                  <a data-toggle='collapse' href='#collapseT9-1' role='button' aria-expanded='false' aria-controls='collapseT9-1'>
                                    <img class='w-75' src='./static/images/tiburones/id/rajiformes/1.png' alt='' />
                                    <p class='f-14' />
                                  </a>
                                  <div class='collapse' id='collapseT9-1'>
                                    <div class='btn-group-vertical f-especie'>
                                      <b>Arhynchobatidae</b>
                                      <a href='javascript:void(0)' class='btn btn-light'>
                                        {' '}
                                        <i>Bathyraja spinosissima</i>
                                      </a>
                                      <b>Rajidae</b>
                                      <a href='javascript:void(0)' class='btn btn-light'>
                                        {' '}
                                        <i>Breviraja nigriventralis</i>
                                      </a>
                                      <a href='javascript:void(0)' class='btn btn-light'>
                                        {' '}
                                        <i>Breviraja spinosa</i>
                                      </a>
                                      <a href='javascript:void(0)' class='btn btn-light'>
                                        {' '}
                                        <i>Dactylobatus armatus</i>
                                      </a>
                                      <a href='javascript:void(0)' class='btn btn-light'>
                                        {' '}
                                        <i>Dactylobatus clarkii</i>
                                      </a>
                                      <a href='javascript:void(0)' class='btn btn-light'>
                                        {' '}
                                        <i>Dipturus bullisi</i>
                                      </a>
                                      <a href='javascript:void(0)' class='btn btn-light'>
                                        {' '}
                                        <i>Dipturus garricki</i>
                                      </a>
                                      <a href='javascript:void(0)' class='btn btn-light'>
                                        {' '}
                                        <i>Dipturus teevani</i>
                                      </a>
                                      <a href='javascript:void(0)' class='btn btn-light'>
                                        {' '}
                                        <i>Rostroraja cervigoni</i>
                                      </a>
                                      <a href='javascript:void(0)' class='btn btn-light'>
                                        {' '}
                                        <i>Rostroraja equatorialis</i>
                                      </a>
                                      <a href='javascript:void(0)' class='btn btn-light'>
                                        {' '}
                                        <i>Rostroraja velezi</i>
                                      </a>
                                      <a href='javascript:void(0)' class='btn btn-light'>
                                        {' '}
                                        <i>Rajella fuliginea</i>
                                      </a>
                                      <a href='javascript:void(0)' class='btn btn-light'>
                                        {' '}
                                        <i>Rajella nigerrima</i>
                                      </a>
                                      <a href='javascript:void(0)' class='btn btn-light'>
                                        {' '}
                                        <i>Rajella purpuriventralis</i>
                                      </a>
                                      <b>Gurgesiellidae</b>
                                      <a href='javascript:void(0)' class='btn btn-light'>
                                        {' '}
                                        <i>Fenestraja plutonia</i>
                                      </a>
                                      <a href='javascript:void(0)' class='btn btn-light'>
                                        {' '}
                                        <i>Fenestraja sinusmexicanus</i>
                                      </a>
                                      <a href='javascript:void(0)' class='btn btn-light'>
                                        {' '}
                                        <i>Gurgesiella atlantica</i>
                                      </a>
                                      <b>Crurirajidae</b>
                                      <a href='javascript:void(0)' class='btn btn-light'>
                                        {' '}
                                        <i>Cruriraja poeyi</i>
                                      </a>
                                      <a href='javascript:void(0)' class='btn btn-light'>
                                        {' '}
                                        <i>Cruriraja rugosa</i>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                                <div class='col-6 col-md-6'>
                                  <a data-toggle='collapse' href='#collapseT9-2' role='button' aria-expanded='false' aria-controls='collapseT9-2'>
                                    <img class='w-75' src='./static/images/tiburones/id/rajiformes/2.png' alt='' />
                                    <p class='f-14' />
                                  </a>
                                  <div class='collapse' id='collapseT9-2'>
                                    <div class='btn-group-vertical f-especie'>
                                      <b>Anacanthobathidae</b>
                                      <a href='javascript:void(0)' class='btn btn-light'>
                                        {' '}
                                        <i>Schroederobatis americana</i>
                                      </a>
                                      <a href='javascript:void(0)' class='btn btn-light'>
                                        {' '}
                                        <i>Springeria longirostris</i>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class='row'>
                            <div class='col-md-12 mt-3 text-center'>
                              <h6>Rhinopristiformes</h6>
                            </div>
                            <div class='col-6 col-md-3'>
                              <a data-toggle='collapse' href='#collapseT10-1' role='button' aria-expanded='false' aria-controls='collapseT10-1'>
                                <img class='w-100' src='./static/images/tiburones/id/rhinopristiformes/1.png' alt='' />
                                <p class='f-14' />
                              </a>
                              <div class='collapse' id='collapseT10-1'>
                                <div class='btn-group-vertical f-especie'>
                                  <b>Pristidae</b>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Pristis pectinata</i>
                                  </a>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Pristis pristis</i>
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div class='col-6 col-md-3'>
                              <a data-toggle='collapse' href='#collapseT11-2' role='button' aria-expanded='false' aria-controls='collapseT11-2'>
                                <img class='w-100' src='./static/images/tiburones/id/rhinopristiformes/2.png' alt='' />
                                <p class='f-14' />
                              </a>

                              <div class='collapse' id='collapseT11-2'>
                                <div class='btn-group-vertical f-especie'>
                                  <b>Rhinobatidae</b>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Pseudobatos glaucostigma</i>
                                  </a>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Pseudobatos leucorhynchus</i>
                                  </a>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Pseudobatos percellens</i>
                                  </a>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Pseudobatos planiceps</i>
                                  </a>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Pseudobatos prahli</i>
                                  </a>
                                </div>
                              </div>
                            </div>

                            <div class='col-6 col-md-3'>
                              <a data-toggle='collapse' href='#collapseT12-3' role='button' aria-expanded='false' aria-controls='collapseT12-3'>
                                <img class='w-100' src='./static/images/tiburones/id/rhinopristiformes/3.png' alt='' />
                                <p class='f-14' />
                              </a>
                              <div class='collapse' id='collapseT12-3'>
                                <div class='btn-group-vertical f-especie'>
                                  <b>Trygonorrhinidae</b>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Zapteryx xyster</i>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class='row'>
                            <div class='col-md-12 mt-3 text-center'>
                              <h6>Myliobatiformes</h6>
                            </div>
                            <div class='col-6 col-md-3'>
                              <a data-toggle='collapse' href='#collapseT13-1' role='button' aria-expanded='false' aria-controls='collapseT13-1'>
                                <img class='w-100' src='./static/images/tiburones/id/myliobatiformes/1.png' alt='' />
                                <p class='f-14' />
                              </a>
                              <div class='collapse' id='collapseT13-1'>
                                <div class='btn-group-vertical f-especie'>
                                  <b>Dasyatidae</b>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Hypanus americanus</i>
                                  </a>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Hypanus dipterurus</i>
                                  </a>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Hypanus guttatus</i>
                                  </a>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Hypanus longus</i>
                                  </a>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Pteroplatytrygon violacea</i>
                                  </a>
                                  <b>Potamotrygonidae</b>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Styracura pacifica</i>
                                  </a>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Styracura schmardae</i>
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div class='col-6 col-md-3'>
                              <a data-toggle='collapse' href='#collapseT14-2' role='button' aria-expanded='false' aria-controls='collapseT14-2'>
                                <img class='w-100' src='./static/images/tiburones/id/myliobatiformes/2.png' alt='' />
                                <p class='f-14' />
                              </a>
                              <div class='collapse' id='collapseT14-2'>
                                <div class='btn-group-vertical f-especie'>
                                  <b>Gymnuridae</b>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Gymnura altavela</i>
                                  </a>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Gymnura crebripunctata</i>
                                  </a>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Gymnura marmorata</i>
                                  </a>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Gymnura micrura</i>
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div class='col-6 col-md-3'>
                              <a data-toggle='collapse' href='#collapseT15-3' role='button' aria-expanded='false' aria-controls='collapseT15-3'>
                                <img class='w-100' src='./static/images/tiburones/id/myliobatiformes/3.png' alt='' />
                                <p class='f-14' />
                              </a>
                              <div class='collapse' id='collapseT15-3'>
                                <div class='btn-group-vertical f-especie'>
                                  <b>Myliobatidae</b>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Myliobatis goodei</i>
                                  </a>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Myliobatis longirostris</i>
                                  </a>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Aetomylaeus asperrimus</i>
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div class='col-6 col-md-3'>
                              <a data-toggle='collapse' href='#collapseT16-3' role='button' aria-expanded='false' aria-controls='collapseT16-3'>
                                <img class='w-100' src='./static/images/tiburones/id/myliobatiformes/4.png' alt='' />
                                <p class='f-14' />
                              </a>
                              <div class='collapse' id='collapseT16-3'>
                                <div class='btn-group-vertical f-especie'>
                                  <b>Urotrygonidae</b>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Urobatis tumbesensis</i>
                                  </a>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Urobatis halleri</i>
                                  </a>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Urobatis jamaicensis</i>
                                  </a>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Urotrygon aspidura</i>
                                  </a>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Urotrygon chilensis</i>
                                  </a>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Urotrygon munda</i>
                                  </a>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Urotrygon reticulata</i>
                                  </a>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Urotrygon rogersi</i>
                                  </a>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Urotrygon venezuelae</i>
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div class='col-6 col-md-3'>
                              <a data-toggle='collapse' href='#collapseT17-3' role='button' aria-expanded='false' aria-controls='collapseT17-3'>
                                <img class='w-100' src='./static/images/tiburones/id/myliobatiformes/5.png' alt='' />
                                <p class='f-14' />
                              </a>
                              <div class='collapse' id='collapseT17-3'>
                                <div class='btn-group-vertical f-especie'>
                                  <b>Mobulidae</b>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Mobula birostris</i>
                                  </a>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Mobula hypostoma</i>
                                  </a>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Mobula mobular</i>
                                  </a>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Mobula munkiana</i>
                                  </a>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Mobula tarapacana</i>
                                  </a>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Mobula thurstoni</i>
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div class='col-6 col-md-3'>
                              <a data-toggle='collapse' href='#collapseT18-3' role='button' aria-expanded='false' aria-controls='collapseT18-3'>
                                <img class='w-100' src='./static/images/tiburones/id/myliobatiformes/6.png' alt='' />
                                <p class='f-14' />
                              </a>
                              <div class='collapse' id='collapseT18-3'>
                                <div class='btn-group-vertical f-especie'>
                                  <b>Aetobatidae</b>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Aetobatus laticeps</i>
                                  </a>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Aetobatus narinari</i>
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div class='col-6 col-md-3'>
                              <a data-toggle='collapse' href='#collapseT19-3' role='button' aria-expanded='false' aria-controls='collapseT19-3'>
                                <img class='w-100' src='./static/images/tiburones/id/myliobatiformes/7.png' alt='' />
                                <p class='f-14' />
                              </a>
                              <div class='collapse' id='collapseT19-3'>
                                <div class='btn-group-vertical f-especie'>
                                  <b>Rhinopteridae</b>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Rhinoptera bonasus</i>
                                  </a>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Rhinoptera brasiliensis</i>
                                  </a>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Rhinoptera steindachneri</i>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class='tab-pane fade' id='quimeras' role='tabpanel' aria-labelledby='quimeras-tab'>
                        <div class='container'>
                          <div class='row'>
                            <div class='col-md-12 mt-3'>
                              <h6>Chimaeriformes</h6>
                            </div>
                            <div class='col-6 col-md-3'>
                              <a data-toggle='collapse' href='#collapseT20-1' role='button' aria-expanded='false' aria-controls='collapseT20-1'>
                                <img class='w-100' src='./static/images/tiburones/id/chimaeriformes/1.png' alt='' />
                                <p class='f-14' />
                              </a>
                              <div class='collapse' id='collapseT20-1'>
                                <div class='btn-group-vertical f-especie'>
                                  <b>Chimaeridae</b>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Hydrolagus alberti</i>
                                  </a>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Chimaera cubana</i>
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div class='col-6 col-md-3'>
                              <a data-toggle='collapse' href='#collapseT21-1' role='button' aria-expanded='false' aria-controls='collapseT21-1'>
                                <img class='w-100' src='./static/images/tiburones/id/chimaeriformes/2.png' alt='' />
                                <p class='f-14' />
                              </a>
                              <div class='collapse' id='collapseT21-1'>
                                <div class='btn-group-vertical f-especie'>
                                  <b>Rhinochimaeridae</b>
                                  <a href='javascript:void(0)' class='btn btn-light'>
                                    {' '}
                                    <i>Neoharriota carri</i>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
              </div>
            </section>

            <section id='s6' class='conservalos'>
              <div class='container'>
                <div class='row d-flex align-items-center'>
                  <div class='col-md-12 text-center'>
                    <p>
                      <img class='img-fluid w-50' src='./static/images/tiburones/como-conservarlos.jpg' alt='' />
                    </p>
                  </div>
                  <div class='col-md-12'>
                    <h2 class='title-sec-comp'>¿Cómo conservamos a los peces cartilaginosos?</h2>

                    <p class='text-justify'>
                      Los peces cartilaginosos son uno de los principales objetivos de un creciente comercio internacional que está ubicando a este recurso en los principales
                      reglones de amenaza y preocupación a nivel de la conservación de sus poblaciones. Se estima según fuentes internacionales que la mayoría de las poblaciones de
                      tiburones con alto valor comercial presentan disminuciones poblacionales cercanas al 90% (Wild Aid, 2001) y más en algunas especies puntuales.{' '}
                    </p>
                    <p class='text-justify'>
                      De manera general para el grupo de peces cartilaginosos, y debido a las características biológicas con ciclos vitales complejos, asociados a lento
                      crecimiento, madurez sexual tardía, producción de pocas crías, se considera uno de los grupos marinos de depredadores claves más vulnerables a nivel global.
                      Su principal amenaza es la sobreexplotación pesquera actual, que no está permitiendo la recuperación de las especies, evidenciando disminuciones considerables
                      de sus poblaciones a nivel mundial.{' '}
                    </p>
                    <p class='text-justify'>
                      Para su protección, en Colombia se han trabajado herramientas como el Libro Rojo de Peces Marinos y el listado de la UICN, donde se hace una evaluación de la
                      vulnerabilidad de las especies y se clasifican según su grado de amenaza (riesgo de extinción). Dado esto, a nivel nacional se considera que aún no se cuenta
                      con especies de tiburones en estado extinto en vida silvestre, no obstante, existen especies clasificadas como En Peligro Crítico (CR) que requieren medidas
                      urgentes. Actualmente, 34 especies de peces condrictios se encuentran incluidos en el Libro Rojo de Peces Marinos (Chasqui et al., 2017), de las cuales 16 son
                      especies amenazadas, 11 están Casi amenazadas, y 7 no tuvieron información suficiente para evaluar su estado con los criterios de la IUCN Red List.{' '}
                    </p>
                    <p class='text-justify'>
                      Considerando estos antecedentes y dado la importancia de contar con información actualizada es necesario contar con un repositorio de información nacional
                      sobre peces cartilaginosos, que facilite el seguimiento del estado de las especies, y la consulta de información de soporte para toma de decisiones a nivel de
                      país, especialmente frente a compromisos y escenarios de gestión internacionales como la Convención sobre el Comercio Internacional de Especies Amenazadas de
                      Fauna y Flora Silvestres - CITES, el Memorando de Entendimiento de tiburones - MdE Tiburones CMS, la Comisión Permanente del Pacífico Sur - CPPS, la Comisión
                      Internacional del Atún Tropical – CIAT, entre otras.{' '}
                    </p>
                    <p class='text-justify'>
                      Este portal es el servicio en línea que funcionará como repositorio de información sobre tiburones, rayas y quimeras, con información biológica y ecológica
                      sobre las especies del grupo con distribución en Colombia, que permitirá el seguimiento del estado de las especies, y pretende ser insumo para toma de
                      decisiones relacionadas con su manejo y conservación a nivel de país. Lo anterior, como medida de implementación en el marco del Plan de Acción Regional y
                      Nacional de Tiburones Rayas y Quimeras (2010), el Plan de especies migratorias (2010) y su Memorando de Entendimiento, entre otros compromisos internacionales
                      en el marco de las competencias del Ministerio de Ambiente y Desarrollo Sostenible.{' '}
                    </p>
                    <p class='text-justify'>
                      En el año 2020, liderado por la fundación Squalus y el Ministerio de Medio ambiente, se llevó a cabo el Taller de Plan de Acción Regional y Nacional de
                      Tiburones Rayas y Quimeras (PAN Tiburones Colombia) con el objetivo de evaluar el proceso de implementación del PAN Tiburones Colombia (2010) e identificar
                      estrategias para incrementar su eficiencia. Después de 10 años se identifica como principal preocupación la reducción en el tamaño poblacional de las especies
                      de Condrictios en Colombia. Las principales amenazas identificadas son; pesca y comercio ilegal, pesca incidental, sobreexplotación pesquera, turismo no
                      regulado, contaminación y degradación de hábitat. Por ello se hace necesario pensar en estrategias que giren en torno a la recuperación de las poblaciones. Se
                      identifican como grandes retos en torno a la implementación del PAN tiburones 1) generar indicadores para medir la efectividad de las acciones que se
                      emprender en pro de la conservación de los Condrictios, 2) que la investigación científica se articule con la toma de decisiones y gobernanza, y 3) generar
                      alternativas para que las regulaciones se cumplan. Lo anterior, sumado a los esfuerzos de los investigadores por conocer datos poblacionales del grupo y el
                      trabajo a nivel socio-ecológico que permita que las comunidades protejan a los Tiburones, Rayas y Quimeras de Colombia.{' '}
                    </p>
                    {/* </div> */}

                    {/* <div class='col-md-12'> */}
                  </div>
                </div>
                <hr />
              </div>
            </section>

            <section id='especies' class='especies'>
              <div class='container'>
                <div class='row'>
                  <div class='col-md-12'>
                    <h2 class='title-sec-comp'>Especies prioritarias para la conservación en Colombia</h2>
                    <div class='row'>
                      {arrary_fichas.map((ficha) => (
                        <div class='col-6 col-md-3 mb-5 mt-2'>
                          <a href='javascript:void(0)' onClick={() => IrFicha(ficha)}>
                            {/* <Redirect to='/ficha-especie' />; */}
                            <p class='title-especie'>
                              <i>{ficha.nombre}</i> <br />
                              {ficha.nombres_comunes}
                            </p>
                            <img class='img-fluid' src={'./static/images/tiburones/especies/json/' + ficha.url_imagen1} alt='' />
                          </a>
                        </div>
                      ))}
                    </div>
                    <hr />
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>
      </section>
      <Footer />
    </div>
  )
}
// sacar los state de (store) que tiene redux y los coloque en las props de este componente.
let mapState = (state) => {
  return {
    arrary_fichas: state.fichas.arrary_fichas,
  }
}
// sacar los dispatch de (store) que tiene redux y los coloque en las props de este componente.
const mapDispatch = (dispatch) => {
  return {
    getAllFichaAction: () => dispatch(getAllFichaAction()),
    setFichaAction: (props) => dispatch(setFichaAction(props)),
    resetObservacionesAction: () => dispatch(resetObservacionesAction()),
  }
}

// connect(mapState,mapDispatch)(this funtion)
export default connect(mapState, mapDispatch)(Home)
