/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from "react";
import Nav from "../base/Nav";
import Header from "../base/Header";
import NavSub1 from "../base/NavSub1";
import Footer from "../base/Footer";
import { Link, useLocation } from "react-router-dom";
import { CSVLink } from "react-csv";
import { URL_APP } from "../../Routes";
import { connect } from "react-redux";
import { getObservacionesAction } from "../../Redux/naturalistaDuck";
import Progress from "../base/Progress";

function Lista({arrary_observaciones,getObservacionesAction,fetching_observaciones}) {
   let url = URL_APP();
   const ref = useRef();
   const location = useLocation().state;
   // const [avistamientosF, setAvistamientosF] = useState(location.observaciones);
   const [avistamientosF, setAvistamientosF] = useState([]);
   
   useEffect(() => {

      if (location) {
         console.log("===CON DATOS===",location)
         setAvistamientosF(location.observaciones)
       } else if (arrary_observaciones.length>0) {
         console.log("==SIN DATOS====",location)
         setAvistamientosF( arrary_observaciones.map((observaciones) => {
            return {
               ...observaciones,
               lat: observaciones.geojson.coordinates[1],
               long: observaciones.geojson.coordinates[0],
            };
         }))
         console.log(arrary_observaciones.map((observaciones) => {
            return {
               ...observaciones,
               lat: observaciones.geojson.coordinates[1],
               long: observaciones.geojson.coordinates[0],
            };
         }))
       }
       else {
          getObservacionesAction();
       }
  
   }, [arrary_observaciones]);
   
  


   
   return (
      <div className='content-principal  content-internas'>
         <header className=' internas-h '>
            <Nav />
            {/* <Header /> */}
         </header>
         <div class='bg-interna'>
            <div class='content-principal content-internas'>
               <header class='internas-h'>
                  <section class='titseccion bg-fondomarino'>
                     <div
                        class='container-xl h-100 d-flex align-items-center'
                        style={{ flexWrap: "wrap" }}
                     >
                        <div class='col-sm-12 col-md-6'>
                           <h2 class='title-modulo'>
                              Visor Tiburones, Rayas y Quimeras de Colombia
                           </h2>
                        </div>
                        <div class='col-sm-12 col-md-4 pt-3'>
                           <form>
                              <div class='input-group mb-3'>
                                 <input
                                    type='text'
                                    class='form-control'
                                    placeholder='Buscar'
                                    aria-label='Buscar'
                                    aria-describedby='button-addon2'
                                 />
                                 <div class='input-group-append'>
                                    <a
                                       class='btn btn-primary'
                                       id='button-addon2'
                                    >
                                       Buscar
                                    </a>
                                 </div>
                              </div>
                           </form>
                        </div>
                        <div class='col-md-2'>
                           <a href='https://minambiente.gov.co'>
                              <img
                                 src='.http://www.invemar.org.co/Invemar3.0-theme/images/img/logos/min-ambiente-gov.jpg'
                                 width='200'
                                 alt=''
                              />
                           </a>
                        </div>
                     </div>
                  </section>
               </header>
               <NavSub1 />

               <section class='contents-interna sig interna-componente'>
                  <div class='container-xl my-3'>
                     {/*contenedor principal*/}
                     <div class='row'>
                        <div class='float-left my-3 ml-3'>
                           <div class='btn-group' role='group'>
                              <a href={url.visor} class='btn btn-light f-14'>
                                 <i class='fa fa-map-marker'></i> Mapa
                              </a>
                              <a href='' class='btn btn-light f-14 active'>
                                 <i class='fa fa-list'></i> Lista
                              </a>
                           </div>
                        </div>
                     </div>
                     <p>&nbsp;</p>
                     <h6>Avistamientos</h6>
                     {fetching_observaciones? <Progress/>: 
                     <div>
                     <div
                        class='row d-flex justify-content-end mb-3 mr-3'
                        style={{ flexWrap: "wrap" }}
                     >
                        <a href=''>
                           <CSVLink
                              data={avistamientosF}
                              filename={"Avistamientos.csv"}
                              className='btn btn-light mr-2 mt-3'
                              target='_blank'
                           >
                              <i className='fa fa-file-text-o'></i> Exportar CSV
                           </CSVLink>
                        </a>
                     </div>
                     <div ref={ref}>
                        {avistamientosF.map((avistamiento) => (
                           <div className='row resultado-avistamiento'>
                              <div
                                 className='col-sm-1 col-md-1 py-0'
                                 style={{ margin: "auto" }}
                              >
                                 <img
                                    // src={avistamiento.taxon.default_photo.medium_url}
                                    src={
                                       avistamiento.photos[0]
                                          ? avistamiento.photos[0].url
                                          : avistamiento.taxon.default_photo
                                               .medium_url
                                    }
                                    alt=''
                                    width='75px'
                                    height='75px'
                                 />
                              </div>
                              <div className='col-sm-6 col-md-6 py-3'>
                                 <b>{avistamiento.species_guess}</b>{" "}
                                 <i>{avistamiento.taxon.name}</i> <br />
                                 <b>Fecha avistamiento:</b>{" "}
                                 {avistamiento.observed_on_string} <br />
                                 <b>Lugar:</b> {avistamiento.place_guess}
                                 <br />
                                 <b>Observador</b>{" "}
                                 {avistamiento.user.name
                                    ? avistamiento.user.name
                                    : avistamiento.user.login}
                              </div>
                              <div className='col-sm-5 col-md-5 py-3'>
                                 <b>URI: </b>
                                 <a href={avistamiento.uri}>
                                    {avistamiento.uri}
                                 </a>
                                 <br />
                                 <b>Estado de amenaza: </b>{" "}
                                 {avistamiento.taxon.conservation_status
                                    ? avistamiento.taxon.conservation_status
                                         .status_name
                                    : "--"}
                                 <br />
                                 <b>Descripcion: </b>{" "}
                                 {avistamiento.desciption
                                    ? avistamiento.desciption
                                    : ""}
                              </div>
                           </div>
                        ))}
                     </div>{" "}
                     </div>
                     }
                  </div>
                  {/*fin contenedor principal*/}
                  <p>&nbsp;</p>
               </section>
            </div>
         </div>
         <Footer />
      </div>
   );
}
// sacar los state de (store) que tiene redux y los coloque en las props de este componente.
let mapState = (state) => {
   return {
      arrary_observaciones: state.naturalista.arrary_observaciones,
      fetching_observaciones:state.naturalista.fetching_observaciones,
   };
};
// sacar los dispatch de (store) que tiene redux y los coloque en las props de este componente.
const mapDispatch = (dispatch) => {
   return {
      getObservacionesAction: (props) =>
         dispatch(getObservacionesAction(props)),
   };
};

// connect(mapState,mapDispatch)(this funtion)
export default connect(mapState, mapDispatch)(Lista);