import Json from "../components/FichaEspecies/Json";
//constantes
let initialState = {
   ver_ficha: [],
   arrary_fichas: [],
   isFichas: false,
};

let SET_FICHA = "VER_FICHA";
let GET_ALL_FICHAS = "GET_ALL_FICHAS";
let RESET_FICHAS = "RESET_FICHAS";
//Reducer
export default function reducer(state = initialState, action) {
   switch (action.type) {
      case RESET_FICHAS:
         return { ...initialState };
      case GET_ALL_FICHAS:
         return {
            ...state,
            arrary_fichas: action.payload.arrary_fichas,
            isFichas: true,
         };
      case SET_FICHA:
         return {
            ...state,
            ver_ficha: action.payload.ver_ficha,
         };
      default:
         return state;
   }
}
export let resetFichasAction = () => (dispatch, getState) => {
   // Aqui la funcion para cerrar sesion en django
   dispatch({
      type: RESET_FICHAS,
   });
};
// Action 1 - get todas las fichas del Json
export let getAllFichaAction = () => (dispatch, getState) => {
   dispatch({
      type: GET_ALL_FICHAS,
      payload: {
         arrary_fichas: Json(),
      },
   });
   // saveStorage(getState());
};

// Action 2 - fichas a mostrar
export let setFichaAction = (ficha) => (dispatch, getState) => {
   // console.log(ficha);
   if (ficha.length === 0) {
      dispatch({
         type: SET_FICHA,
         payload: {
            ver_ficha: [],
         },
      });
   } else {
      dispatch({
         type: SET_FICHA,
         payload: {
            ver_ficha: ficha,
         },
      });
   }
   saveStorage(getState());
};

// aux 1
function saveStorage(storage) {
   localStorage.storage = JSON.stringify(storage);
}

//aux 2
export let restoreFichaAction = () => (dispatch, getState) => {
   let storage = localStorage.getItem("storage");
   storage = JSON.parse(storage);
   if (storage) {
      // dispatch({
      //    type: GET_ALL_FICHAS,
      //    payload: {
      //       arrary_fichas: storage.fichas.arrary_fichas,
      //    },
      // });

      dispatch({
         type: SET_FICHA,
         payload: {
            ver_ficha: storage.fichas.ver_ficha,
         },
      });
   }
};
