import React from "react";
import HederFicha from "../../components/base/HederFicha";
import { Redirect } from "react-router-dom";
import URL_APP from "../../Routes";
import { connect } from "react-redux";
import Footer from ".././base/Footer";

function Ficha({ ficha }) {
   let url = URL_APP();

   if (ficha.length === 0) return <Redirect to='/' />;
   return (
      <div class='content-principal  content-internas' id='fragment'>
         <HederFicha titulo={ficha.nombre} />

         <section class='contents-interna content-blank interna-componente'>
            <nav aria-label='breadcrumb px-0'>
               <ol class='breadcrumb px-0'>
                  <li class='breadcrumb-item'>
                     <a href='/'>Tiburones, Rayas y Quimeras</a>
                  </li>
                  <li class='breadcrumb-item active' aria-current='page'>
                     {ficha.nombres_comunes}
                  </li>
               </ol>
            </nav>
            <div class='container-xl mb-3'>
               <div class='row'>
                  <div class='col-md-12 mb-3'>
                     <div class='row mb-3'>
                        <div class='col-md-8'>
                           <div class='con_arbolfichaesp'>
                              <div class='cont_listarbol'>
                                 <ul class='listaarbol_ficha'>
                                    <li
                                       class='ini-arbol'
                                       styles={{ fontStyle: "italic" }}
                                    >
                                       <span>REINO:</span> ANIMALIA
                                       <ul>
                                          <li>
                                             <span>PHYLUM:</span>{" "}
                                             {ficha.clasificación.phylum}
                                             <ul>
                                                <li>
                                                   <span>CLASE:</span>{" "}
                                                   {ficha.clasificación.clase}
                                                   <ul>
                                                      <li>
                                                         <span>ORDEN:</span>{" "}
                                                         {
                                                            ficha.clasificación
                                                               .orden
                                                         }
                                                         <ul>
                                                            <li>
                                                               <span>
                                                                  FAMILIA:
                                                               </span>{" "}
                                                               {
                                                                  ficha
                                                                     .clasificación
                                                                     .familia
                                                               }
                                                               <ul>
                                                                  <li>
                                                                     <span>
                                                                        GÉNERO:
                                                                     </span>{" "}
                                                                     {
                                                                        ficha
                                                                           .clasificación
                                                                           .genero
                                                                     }
                                                                  </li>
                                                               </ul>
                                                            </li>
                                                         </ul>
                                                      </li>
                                                   </ul>
                                                </li>
                                             </ul>
                                          </li>
                                       </ul>
                                    </li>
                                 </ul>
                              </div>
                              <h4>
                                 <strong></strong>
                              </h4>
                           </div>
                           <h6>Nombres comunes</h6>
                           <p>
                              <i> {ficha.nombres_comunes} </i>
                           </p>
                        </div>
                        <div class='col-md-4 mb-3'>
                           <div
                              id='carouselExampleControls'
                              class='carousel slide'
                              data-ride='carousel'
                           >
                              <div class='carousel-inner'>
                                 <div class='carousel-item active'>
                                    <img
                                       src={
                                          "./static/images/tiburones/especies/json/" +
                                          ficha.url_imagen1
                                       }
                                       class='d-block w-100'
                                       alt='...'
                                    />
                                    <div class='carousel-caption d-none d-md-block'>
                                       <p
                                          class='f-14 text-light'
                                          styles={{
                                             position: "absolute",
                                             top: "10px",
                                          }}
                                       >
                                          {/* ©John Norton. */}
                                       </p>
                                    </div>
                                 </div>
                                 <div class='carousel-item'>
                                    <img
                                       src={
                                          "./static/images/tiburones/especies/json/" +
                                          ficha.url_imagen3
                                       }
                                       class='d-block w-100'
                                       alt='...'
                                    />
                                    <div class='carousel-caption d-none d-md-block'>
                                       <p
                                          class='f-14 text-light'
                                          styles={{
                                             position: "absolute",
                                             top: "10px",
                                          }}
                                       >
                                          {ficha.cita_imagen3}
                                       </p>
                                    </div>
                                 </div>
                              </div>
                              <a
                                 class='carousel-control-prev'
                                 href='#carouselExampleControls'
                                 role='button'
                                 data-slide='prev'
                              >
                                 <span
                                    class='carousel-control-prev-icon'
                                    aria-hidden='true'
                                 ></span>
                                 <span class='sr-only'>Previous</span>
                              </a>
                              <a
                                 class='carousel-control-next'
                                 href='#carouselExampleControls'
                                 role='button'
                                 data-slide='next'
                              >
                                 <span
                                    class='carousel-control-next-icon'
                                    aria-hidden='true'
                                 ></span>
                                 <span class='sr-only'>Next</span>
                              </a>
                           </div>
                        </div>
                     </div>
                     <div class='row'>
                        <div class='col-md-12' style={{ textAlign: "justify" }}>
                           <hr />
                           <h6>Distribución y hábitat</h6>
                           <div class='row mt-3'>
                              <div
                                 class='col-md-12 d-flex justify-content-center'
                                 style={{ flexWrap: "wrap" }}
                              >
                                 <img
                                    class='w-75'
                                    src={
                                       "./static/images/tiburones/especies/json/" +
                                       ficha.url_mapa_distribucion
                                    }
                                    alt=''
                                 />
                                 <cite>{ficha.cita_mapa}</cite>
                                 <p class='mt-3'>{ficha.distribución}</p>
                              </div>
                           </div>
                           <hr />

                           <div
                              class='row d-flex align-items-center'
                              style={{ flexWrap: "wrap" }}
                           >
                              <div class='col-md-12'>
                                 <h6>Caracteres diagnósticos</h6>
                                 <p>{ficha.caracteres_diagnósticos}</p>
                              </div>
                              <div class='col-md-12 text-center '>
                                 <img
                                    class='img-fluid text-center '
                                    src={
                                       "./static/images/tiburones/especies/json/" +
                                       ficha.url_imagen_caracteres_diagnósticos
                                    }
                                    alt=''
                                 />
                                 <cite class='d-block'>
                                    {ficha.cita_imagen_cd}
                                 </cite>
                              </div>
                           </div>
                           <hr />

                           <h6>Ecología</h6>
                           <p>{ficha.ecología}</p>
                           <h6>Reproducción</h6>
                           <p>{ficha.reproduccion}</p>
                           <hr />
                           <h6>Amenazas en Colombia</h6>
                           <p>{ficha.amenazas_en_Colombia}</p>
                           <hr />
                           <div class='row'>
                              <div class='col-md-8'>
                                 <h6>Medidas de Conservación</h6>
                                 <p>{ficha.medidas_de_conservacion}</p>
                              </div>
                              <div class='col-md-4 mt-4'>
                                 <img
                                    class='img-fluid'
                                    src={
                                       "./static/images/tiburones/especies/json/" +
                                       ficha.url_iniciativas_de_conservacion
                                    }
                                    alt=''
                                 />
                              </div>
                           </div>
                           <hr />
                           <h6>Población</h6>
                           <p>{ficha.poblacion}</p>
                           <h6>Nota sobre fuente de la información</h6>
                           <p>{ficha.nota_sobre_fuente_de_la_informacion}</p>
                           <hr />
                           <h6>Ficha SiAM</h6>
                           <a href={ficha.ficha_siam} target="_blank">{ficha.ficha_siam}</a>
                           <h6>Ficha SIB</h6>
                           <a href={ficha.ficha_sbm}>{ficha.ficha_sbm}</a>
                           <hr />
                        </div>
                     </div>
                  </div>
                  <p>&nbsp;</p>
               </div>
            </div>
         </section>
         <Footer />
      </div>
   );
}
// sacar los state de (store) que tiene redux y los coloque en las props de este componente.
let mapState = (state) => {
   return {
      ficha: state.fichas.ver_ficha,
   };
};
// sacar los dispatch de (store) que tiene redux y los coloque en las props de este componente.
const mapDispatch = (dispatch) => {
   return {};
};

// connect(mapState,mapDispatch)(this funtion)
export default connect(mapState, mapDispatch)(Ficha);