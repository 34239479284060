import React from "react";

export default function Footer() {
   return (
      <footer>
      <div id="footerlogos">
          <a href="https://www.minambiente.gov.co/" target="_blank" rel="noopener noreferrer">
              <img className="footer-minambiente" src="http://www.invemar.org.co/Invemar3.0-theme/images/img/logos/minambiente.png" alt="footer-minambiente"/>
          </a>
          <section className="marcas-apoyo">
              <ul className="slider-m">
                  <li>
                      <a href="http://www.siac.gov.co"><img src="/static/images/marcas-apoyo/logo-siac.jpg"
                              alt="" /></a>
                  </li>

                  <li>
                      <a href="http://siatac.co"><img src="/static/images/marcas-apoyo/logo-siat.jpg" alt="" /></a>
                  </li>

                  <li>
                      <a href="http://www.sibcolombia.net"><img src="/static/images/marcas-apoyo/logo-sib.jpg"
                              alt="" /></a>
                  </li>

                  <li>
                      <a href="https://www.gobiernoenlinea.gov.co"><img
                              src="/static/images/marcas-apoyo/logo-goblinea.jpg" alt="" /></a>
                  </li>
              </ul>
          </section>
      </div>
      <div className="content">
          <nav>
              <ul>
                  <li><a href="/">Home</a></li>

                  <li><a href="https://siam.invemar.org.co/noticias">Noticias</a></li>

                  <li><a href="https://siam.invemar.org.co/descripcion">Modelo Conceptual</a></li>

                  <li><a href="http://ayudas.invemar.org.co/web/siam">Ayuda</a></li>

                  <li><a href="https://siam.invemar.org.co/informacion-geografica">Servicios</a></li>
                  
                    {/* <li>
                <a
                   href="http://ayudas.invemar.org.co/web/siam"
                   target="_blank"
                >
                   <i className="fa fa-question-circle" title="Ayuda"></i>
                </a>
             </li> */}
              </ul>
          </nav>
          <div className="dat-direccionales">
              <p>
                  SEDE PRINCIPAL:PLAYA SALGUERO - RODADERO -SANTA MARTA, COLOMBIA- |
                  A.A. 1016 | TELÉFONOS <br />
                  (+57)(+5)4328600 - FAX: (+57) (+5) 4328682<br />
                  HORARIO DE ATENCIÓN (STA MARTA): DE 7:00 A 11:15 AM Y DE 1:00 A 4:00
                  PM DE LUNES A VIERNES <br />
                  COPYRIGHT © 2012 TODOS LOS DERECHOS RESERVADOS
                  WEBMASTER@INVEMAR.ORG.CO
              </p>
              <p>
                  <a style={{color: 'white'}} href="http://www.invemar.org.co/directriz-privacidad" target="">
                      DIRECTIVA DE PRIVACIDAD |
                  </a>

                  <a style={{color: 'white'}} href="/compromiso-uso" target="">
                      COMPROMISO DE USO
                  </a>
              </p>
              <p className="copyright">
                  © 2014 invemar all right reserved property of invemar
              </p>
          </div>
          <div className="logo-footer">
              <img src="/static/images/logo-invemar.png" alt="invemar" />
          </div>
      </div>
  </footer>
   );
}
