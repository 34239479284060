import React from "react";

export default function HederFicha({ titulo }) {
   return (
      <div>
         <header class=' internas-h '>
            <section class='header-prin'>
               <div class='logo-invemar'>
                  <a title='invemar' href='http://www.invemar.org.co/'>
                     <img src='./static/images/logo-invemar.png' alt='invemar' />
                  </a>
               </div>
               <div class='marca-minambiente'>
                  <img
                     src='./static/images/marca-minambiente.png'
                     alt=' todos por un nuevo pais - minambiente'
                  />
               </div>
               <div id='siamtitulo'>
                  <big>SiAM</big>
                  <small>
                     <span>Sistema de Información</span>
                     <span> Ambiental Marina</span>
                  </small>
               </div>
               <div class='menu-mobil mm-internas' id='showMenuInt'>
                  <a href='#'>
                     <i class='fa fa-bars font-menu-mobil'></i>
                  </a>
               </div>
               <nav class='nav-principal nav-interiores' id='nav_principal'>
                  <div class='btn-hide-menu' id='hideMenuInt'>
                     <a href='#'>
                        <img src='./static/images/btn-cerrar.png' alt='' />
                        Cerrar
                     </a>
                  </div>
                  <ul>
                     <li>
                        <i class='fa fa-home margin-icon-1 font-size-20'></i>
                        <a href='/' target=''>
                           Inicio
                        </a>
                     </li>
                     <li>
                        <a href='/descripcion' target=''>
                           ¿Qué es SiAM?
                        </a>
                     </li>
                     <li>
                        <a href='/cifras_siam' target=''>
                           Cifras SIAM
                        </a>
                     </li>
                     <li>
                        <a href='/mapa-sitio' target=''>
                           Mapa sitio
                        </a>
                     </li>
                     <li>
                        <a
                           href='http://www.invemar.org.co/web/guest/acuerdo-de-acceso-uso-a-datos'
                           target='_blank'
                        >
                           Solicitud de información
                        </a>
                     </li>
                     <li>
                        <a href='/noticias' target=''>
                           Noticias
                        </a>
                     </li>
                     <li class='targetli'>
                        <a href='#'>Herramientas</a>
                        <div class='submenu'>
                           <div class='submenu-item'>
                              <a href='#'>Transversales</a>
                              <ul class='submenusub'>
                                 <li>
                                    <a href='/informacion-geografica' target=''>
                                       Sección de Información Geográfica
                                    </a>
                                 </li>

                                 <li>
                                    <a href='/documentos' target=''>
                                       Recursos Centro Documentación
                                    </a>
                                 </li>

                                 <li>
                                    <a href='/galerias' target=''>
                                       Galerías
                                    </a>
                                 </li>

                                 <li>
                                    <a href='/herramientas' target=''>
                                       Herramientas
                                    </a>
                                 </li>

                                 <li>
                                    <a href='/campanas-proyectos' target=''>
                                       Proyectos y Estaciones
                                    </a>
                                 </li>
                              </ul>
                           </div>
                        </div>
                     </li>
                     <li>
                        <a href='/contactenos' target=''>
                           Contáctenos
                        </a>
                     </li>
                  </ul>
                  <ul class='nav-ayudas'>
                     <li>
                        <a href='/buscador'>
                           <i class='fa fa-search' title='Buscar'></i>
                        </a>
                     </li>
                     <li>
                        <a
                           href='http://ayudas.invemar.org.co/web/siam'
                           target='_blank'
                        >
                           <i class='fa fa-question-circle' title='Ayuda'></i>
                        </a>
                     </li>
                     <li>
                        <a href='/informacion-geografica'>
                           <i
                              class='fa fa-globe'
                              title='Información Geográfica'
                           ></i>
                        </a>
                     </li>
                  </ul>
               </nav>
            </section>

            <section class='titseccion bg-bluelight'>
               <div
                  class='container-xl h-100 d-flex align-items-center'
                  style={{ flexWrap: "wrap" }}
               >
                  <div class='col-12 col-sm-6 col-md-6'>
                     <h4>
                        <i>{titulo}</i>                       
                     </h4>
                  </div>
               </div>
            </section>
         </header>
      </div>
   );
}
