import { Switch, Route } from "react-router-dom";

import Visor from "./components/home/Visor";
import Ficha from "./components/FichaEspecies/Ficha";
import Home from "./components/home/Home";
import Avistamientos from "./components/home/avistamientos";
import Lista from "./components/home/lista";

export default function Routes() {
   let url = URL_APP();
   return (
      <Switch>
         <Route path={url.ficha_especie} component={Ficha} />
         <Route exact path={url.home} component={Home} />
         <Route exact path={url.visor} component={Visor} />
         <Route exact path={url.avistamiento} component={Avistamientos} />
         <Route exact path={url.lista_consulta} component={Lista} />
      </Switch>
   );
}

export let URL_APP = () => {
   return {
      home: "/",
      visor: "/visor",
      ficha_especie: "/ficha-especie",
      avistamiento: "/avistamientos",
      registrar_avistamiento:"https://www.inaturalist.org/projects/tiburones-rayas-y-quimeras-de-colombia",
      lista_consulta: "/Lista-consulta",
      naturalista:
         "https://www.inaturalist.org/projects/tiburones-rayas-y-quimeras-de-colombia",
         manual:"https://n2t.net/ark:/81239/m93m5c",
   };
};
export let TXT_APP = () => {
   return {
      titulo_visor: "Visor Avistamientos de Tiburones, Rayas y Quimeras de Colombia",
   };
};
