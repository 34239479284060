import { createStore, combineReducers, compose, applyMiddleware } from "redux";
import naturalistaReducer, {
   restoreNaturalistaAction,
   getObservacionesAction,
} from "./naturalistaDuck";
import fichasReducer, {
   getAllFichaAction,
   restoreFichaAction,
} from "./fichasDuck";

import thunk from "redux-thunk";

let rootReducer = combineReducers({
   naturalista: naturalistaReducer,
   fichas: fichasReducer,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function generateStore() {
   let store = createStore(
      rootReducer,
      composeEnhancers(applyMiddleware(thunk))
   );

   // restoreNaturalistaAction()(store.dispatch, store.getState);
   // restoreFichaAction()(store.dispatch, store.getState);

   return store;
}
